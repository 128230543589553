import { getUserAuthState } from '@graphql/operations/user';

export const mutations = {
    /**
     * Set the user authenticated state.
     *
     * @param  {Object}  response                  The mutation response (not applicable).
     * @param  {Boolean} options.isAuthenticated   The authenticated state of the user.
     * @param  {Object}  options.cache             The Apollo Client cache instance.
     */
    setUserAuthState: (response, { isAuthenticated }, { cache }) => {
        const data = cache.readQuery({
            query: getUserAuthState,
        });

        data.user.isAuthenticated = isAuthenticated;

        cache.writeData({ data });
    },
};

export const initialCacheState = {
    user: {
        isAuthenticated: null,
        __typename: 'User',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
///////////////////
/////////////////////////////////////
/////////
//////
//

////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////////
//////////
