import Vue from 'vue';
import VueMq from 'vue-mq';
import {
    CPAP_THEME_MQ_BREAKPOINT_XS,
    CPAP_THEME_MQ_BREAKPOINT_SM,
    CPAP_THEME_MQ_BREAKPOINT_MD,
    CPAP_THEME_MQ_BREAKPOINT_LG,
    CPAP_THEME_MQ_BREAKPOINT_XL,
} from '@constants/theme';

Vue.use(VueMq, {
    defaultBreakpoint: 'xs',
    breakpoints: {
        xs: CPAP_THEME_MQ_BREAKPOINT_XS,
        sm: CPAP_THEME_MQ_BREAKPOINT_SM,
        md: CPAP_THEME_MQ_BREAKPOINT_MD,
        lg: CPAP_THEME_MQ_BREAKPOINT_LG,
        xl: CPAP_THEME_MQ_BREAKPOINT_XL,
        xxl: Infinity,
    },
});
