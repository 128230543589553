import { gql } from 'graphql-tag';

export const BreadcrumbsFragment = gql`
    fragment BreadcrumbsFragment on Breadcrumb {
        category_id
        category_level
        category_name
        category_url_path
        plp_url
    }
`;
