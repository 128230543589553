import Vue from 'vue';
import { CPAP_STORAGE_KEY_PREFIX, PREVIEW_MODE_KEY } from '@constants/localStorageKeys';

/**
 * Set the preview mode state.
 *
 * @param {object} options.app    The app context object.
 * @param {object} options.route  The contextual route data.
 * @param {object} inject         Inject values into the app instance.
 */
export default async ({ app, route }, inject) => {
    // Create the observable reactive property variable.
    const previewModeStore = Vue.observable({
        isPreviewing: false,
    });

    let isPreviewing = app.$cookies?.get(CPAP_STORAGE_KEY_PREFIX + PREVIEW_MODE_KEY);

/////////////////////////////////////
///////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////
/////////////////////////////
/////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////
/////
//////////////

    // Determine if the route query has the preview flag.
    // Public page requests with the `preview` query string should never be cached at the edge server
    if (isPreviewing) {
        previewModeStore.isPreviewing = true;
    }

    inject('previewMode', previewModeStore);
};
