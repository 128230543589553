import Vue from 'vue';

Vue.directive('scroll-to-anchor', {
    bind(element, binding, vnode) {
        const { to } = vnode.componentOptions.propsData;
        const { $route } = vnode.componentInstance;

        // Only attach anchor scrolling when a hash object key is provided
        if (typeof to === 'string') {
            return;
        }

        element.clickEvent = async event => {
            const { scrollToAnchor } = await import('@utilities/scrollToAnchor');
            const isOnSamePage = to.path.trim() === $route.path;

            setTimeout(
                () => scrollToAnchor((to.hash ? `#${to.hash}` : null) || '#app'),
                isOnSamePage ? 0 : 1000,
            );
        };

        element.addEventListener('click', element.clickEvent);
    },
    unbind: element => element.removeEventListener('click', element.clickEvent),
});
