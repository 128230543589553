/**
 * Find variant by matching cart item's configurable option UID to variant attribute UID.
 *
 * @param {object} cartItem CartItemIterface
 *
 * @return {object} ConfigurableVariant|null
 */
export const getCartItemSelectedVariant = cartItem => {
    const variants = cartItem?.product?.variants || [];
    const cartItemVariantUid = cartItem?.configurable_options?.[0]?.configurable_product_option_value_uid;

    return variants.find(variant =>
        variant?.attributes?.[0]?.uid === cartItemVariantUid,
    );
};
