import { ApolloLink } from '@apollo/client/link/core';
import { Observable } from '@apollo/client/core';
import { isExpectedOperationType } from '@utilities/apollo/isExpectedOperationType';

/**
 * Create an Apollo link instance that enqueues mutations so that they cannot fire in parallel.
 *
 * To add to the queue pass the `{ context: { queue: true || 'QUEUE_KEY_NAME' } }` context to your mutation.
 */
export const createMutationQueueLink = () => {
    const queue = {
        default: [],
    };
    let isProcessing = false;

    return new ApolloLink((operation, forward) => {
        /**
         * Process a mutation operation.
         *
         * @param  {object} entry Queueable operation entry.
         */
        const processOperation = entry => {
            isProcessing = true;

            entry.forward(entry.operation).subscribe({
                next: response => {
                    isProcessing = false;
                    entry.observer.next(response);

                    // If there are more operations, process them.
                    if (queue[entry.queueKey]?.length) {
                        processOperation(queue[entry.queueKey].shift());
                    }
                },
                error: error => {
                    isProcessing = false;
                    entry.observer.error(error);

                    // If there are more operations, process them.
                    if (queue[entry.queueKey]?.length) {
                        processOperation(queue[entry.queueKey].shift());
                    }
                },
                complete: entry.observer.complete.bind(entry.observer),
            });
        };

        /**
         * Cancel a queued mutation operation.
         *
         * @param  {object} entry Queueable operation entry.
         */
        const cancelOperation = entry => {
            if (queue[entry.queueKey]?.length) {
                queue[entry.queueKey] = queue[entry.queueKey].filter(e => e !== entry);
            }
        };

        /**
         * Queue up a pending mutation operation.
         *
         * @param  {object} entry Queueable operation entry.
         */
        const enqueue = entry => {
            if (!queue[entry.queueKey]) {
                queue[entry.queueKey] = [];
            }

            queue[entry.queueKey].push(entry);
        };

        // Get the queue information from the operation context
        const contextQueue = operation.getContext()?.queue;

        // Only enqueue mutations operations
        if (isExpectedOperationType(operation, 'mutation') && contextQueue) {
            return new Observable(observer => {
                const queueKey = typeof contextQueue === 'string' ? contextQueue : 'default';
                const operationEntry = { operation, forward, observer, queueKey };

                // Determine if a mutation operation is currently being processed
                if (isProcessing) {
                    // Enqueue the operation entry data if a mutation operation is currently being processed
                    enqueue(operationEntry);
                } else {
                    processOperation(operationEntry);
                }

                return () => cancelOperation(operationEntry);
            });
        } else {
            return forward(operation);
        }
    });
};
