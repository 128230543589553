import { CPAP_STORAGE_KEY_PREFIX, REDIRECT_FROM_KEY } from '@constants/localStorageKeys';

/**
 * Set `originalLocation` in dataLayer if this is from a redirect.
 *
 * @param  {Object} options.app     The app context object.
 */
export default ({ app }) => {
    // This is the entry point to the app and is being redirected through authentication middleware.
    // Lets push the original location for consumption in GTM so we don't lose campaign parameters.
    if (process.server) {
        const redirectUrl = app.$cookies.get(CPAP_STORAGE_KEY_PREFIX + REDIRECT_FROM_KEY);

        if (redirectUrl) {
            app.$gtm.push({
                originalLocation: app.$config.baseUrl + redirectUrl,
            });

            // Remove any cookies related to the previous redirect path.
            app.$cookies.remove(CPAP_STORAGE_KEY_PREFIX + REDIRECT_FROM_KEY);
        }
    }
};
