import { getCartItemSelectedVariant } from '@utilities/cart/item/getCartItemSelectedVariant';
import { PRODUCT_AVAILABILITY_LABEL_FOR_REFERENCE_ONLY } from '@constants/productAvailabilityLabels';

/**
 * Determine if a cart item is flagged as reference only.
 *
 * @param  {object}  cartItem The CartItemIterface data set.
 *
 * @return {boolean}
 */
export const isCartItemReferenceOnly = cartItem => {
    const { product } = cartItem || {};
    const availabilityLabel = product?.product_availability_label;

    const isForReferenceOnly = availabilityLabel === PRODUCT_AVAILABILITY_LABEL_FOR_REFERENCE_ONLY;

    // If the parent product is flagged as reference only, then
    // all variants should be as well and we can exit early
    if (isForReferenceOnly) {
        return true;
    }

    // Determine if the cart item has any variant configurable options
    if (!cartItem?.configurable_options?.length) {
        return false;
    }

    // Verify reference only configurable products
    const variant = getCartItemSelectedVariant(cartItem);

    const variantAvailabilityLabel = variant?.product?.product_availability_label;

    const isVariantForReferenceOnly = variantAvailabilityLabel === PRODUCT_AVAILABILITY_LABEL_FOR_REFERENCE_ONLY;

    return isVariantForReferenceOnly;
};
