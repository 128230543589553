/**
 * Helper function to make sure a path is an absolute path.
 *
 * @param  {string} path The URL path.
 *
 * @return {string}
 */
export const getAbsoluteUrlPath = path => {
    if (!path || typeof path !== 'string') {
        return '';
    }

    const hasScheme = path.startsWith('http');
    const fullPath = (path.startsWith('/') || hasScheme ? path : `/${path}`).replace(/\/+/g, '/');

    return hasScheme ? fullPath.replace(':/', '://') : fullPath;
};
