const middleware = {}

middleware['authenticate'] = require('../src/middleware/authenticate.js')
middleware['authenticate'] = middleware['authenticate'].default || middleware['authenticate']

middleware['blogRedirect'] = require('../src/middleware/blogRedirect.js')
middleware['blogRedirect'] = middleware['blogRedirect'].default || middleware['blogRedirect']

middleware['checkForPromotions'] = require('../src/middleware/checkForPromotions.js')
middleware['checkForPromotions'] = middleware['checkForPromotions'].default || middleware['checkForPromotions']

middleware['gaSetRedirectOriginalRoute'] = require('../src/middleware/gaSetRedirectOriginalRoute.js')
middleware['gaSetRedirectOriginalRoute'] = middleware['gaSetRedirectOriginalRoute'].default || middleware['gaSetRedirectOriginalRoute']

middleware['gaSiteSpeedSampleRate'] = require('../src/middleware/gaSiteSpeedSampleRate.js')
middleware['gaSiteSpeedSampleRate'] = middleware['gaSiteSpeedSampleRate'].default || middleware['gaSiteSpeedSampleRate']

middleware['resetProductComparisons'] = require('../src/middleware/resetProductComparisons.js')
middleware['resetProductComparisons'] = middleware['resetProductComparisons'].default || middleware['resetProductComparisons']

middleware['routeResolution'] = require('../src/middleware/routeResolution.js')
middleware['routeResolution'] = middleware['routeResolution'].default || middleware['routeResolution']

middleware['splitTestingVariationPlacement'] = require('../src/middleware/splitTestingVariationPlacement.js')
middleware['splitTestingVariationPlacement'] = middleware['splitTestingVariationPlacement'].default || middleware['splitTestingVariationPlacement']

middleware['unauthenticated'] = require('../src/middleware/unauthenticated.js')
middleware['unauthenticated'] = middleware['unauthenticated'].default || middleware['unauthenticated']

middleware['validatePreviousAuthentication'] = require('../src/middleware/validatePreviousAuthentication.js')
middleware['validatePreviousAuthentication'] = middleware['validatePreviousAuthentication'].default || middleware['validatePreviousAuthentication']

export default middleware
