import { gql } from 'graphql-tag';

const mobileMenuFooterFixedParameterTypes = `
    $data: JSON,
    $options: JSON,
    $props: JSON,
    $emissionHandlers: JSON,
`;

const mobileMenuFooterFixedParameters = `
    data: $data,
    options: $options,
    props: $props,
    emissionHandlers: $emissionHandlers,
`;

export const getMobileMenuFooterFixed = gql`
    query getMobileMenuFooterFixed {
        mobileMenuFooterFixed @client {
            data
        }
    }
`;

export const replaceMobileMenuFooterFixedParameters = gql`
    mutation replaceMobileMenuFooterFixedParameters(
        ${mobileMenuFooterFixedParameterTypes}
    ) {
        replaceMobileMenuFooterFixedParameters(
            ${mobileMenuFooterFixedParameters}
        ) @client
    }
`;
