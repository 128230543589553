export const accessoryFields = `
    uid # Required for the Apollo cache unique fragment identifier
    id # Required for the Apollo cache unique fragment identifier
    sku
    name
    url_key
    main_image
    # The 'manufacturer' key must always be included for 'brand' to return proper data
    manufacturer
    brand {
        id
        label
    }
    displayable_in_stock
    displayable_allow_backorders
    # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
    product_availability
    product_availability_label
    product_overview
    rx_required
    max_sale_qty
    upsell_text
    price_tiers {
        quantity
    }
    advanced_pricing {
        msrp_display_actual_price_type
    }
    price_range {
        minimum_price {
            final_price {
                value
            }
            regular_price {
                value
            }
            discount {
                amount_off
                percent_off
            }
            promotion_discount @include(if: $hasPromoCode) {
                applies_to_product
                amount_off_regular_price
                percent_off_regular_price
                price {
                    value
                }
            }
        }
    }
    ... on ConfigurableProduct {
        configurable_options {
            uid # Required for the Apollo cache unique fragment identifier
            label
            values {
                uid # Required for the Apollo cache unique fragment identifier
                label
            }
        }
        variants {
            attributes {
                uid # Required for the Apollo cache unique fragment identifier
                label
            }
            product {
                uid # Required for the Apollo cache unique fragment identifier
                id # Required for the Apollo cache unique fragment identifier
                sku
                name
                # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                product_availability
                product_availability_label
                displayable_in_stock
                upsell_text
                displayable_allow_backorders
                customization_cart_pricing_flag
                categories {
                    name
                    url_path
                    plp_url
                }
                price_range {
                    minimum_price {
                        final_price {
                            value
                        }
                        regular_price {
                            value
                        }
                    }
                }
            }
        }
    }
`;
