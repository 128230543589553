import { gql } from 'graphql-tag';

export const getGeoLocationState = gql`
    query getGeoLocationState {
        location @client {
            country
            state
        }
    }
`;

export const setGeoLocationState = gql`
    mutation setLocation($country: String!, $state: String!) {
        setLocation(country: $country, state: $state) @client
    }
`;
