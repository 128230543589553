import { gql } from 'graphql-tag';

export const DiscountSummaryFragment = gql`
    fragment DiscountSummaryFragment on CartPrices {
        discounts {
            amount {
                currency
                value
            }
            label
        }
    }
`;

export const TaxSummaryFragment = gql`
    fragment TaxSummaryFragment on CartPrices {
        applied_taxes {
            amount {
                currency
                value
            }
        }
    }
`;

export const SubTotalPriceFragment = gql`
    fragment SubTotalPriceFragment on CartPrices {
        subtotal_excluding_tax {
            currency
            value
        }
    }
`;

export const GrandTotalPriceFragment = gql`
    fragment GrandTotalPriceFragment on CartPrices {
        grand_total {
            currency
            value
        }
    }
`;

export const SubtotalFragment = gql`
    fragment SubtotalFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        prices {
            ...SubTotalPriceFragment
        }
    }
    ${SubTotalPriceFragment}
`;

export const GrandTotalFragment = gql`
    fragment GrandTotalFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        prices {
            ...GrandTotalPriceFragment
        }
    }
    ${GrandTotalPriceFragment}
`;

export const PriceSummaryFragment = gql`
    fragment PriceSummaryFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        prices {
            ...TaxSummaryFragment
            ...DiscountSummaryFragment
        }
        ...SubtotalFragment
        ...GrandTotalFragment
    }
    ${TaxSummaryFragment}
    ${DiscountSummaryFragment}
    ${GrandTotalFragment}
    ${SubtotalFragment}
`;
