import { gql } from 'graphql-tag';

export const getRoutingMetaData = gql`
    query getRoutingMetaData {
        routingMetaData @client {
            resolutionCount
            isInitialPageLoad
            resolvedPaths
        }
    }
`;

export const logResolvedRoute = gql`
    mutation logResolvedRoute($path: String!) {
        logResolvedRoute(path: $path) @client
    }
`;
