import posthog from 'posthog-js';
import Vue from 'vue';
import { logError } from '@utilities/logError';
import { Integrations } from '@sentry/tracing';

export default async ({ app, $config, $cookies }, inject) => {
    // Create the observable reactive property variable.
    const posthogFeatureFlagStore = Vue.observable({});

    inject('posthogFeatureFlags', posthogFeatureFlagStore);

    if (process.client) {
        let isInitialFeatureFlagLoad = true;

        posthog.onFeatureFlags(() => {
            const updatedFlags = posthog.featureFlags.getFlagVariants();

            const flagKeys = Object.keys(updatedFlags);

            if (flagKeys.length) {
                flagKeys.forEach(key => {
                    Vue.set(posthogFeatureFlagStore, key, updatedFlags[key]);
                });
            }

            if (
                isInitialFeatureFlagLoad &&
                // Checking for an explicit `true` value should not be necessary, but we'll try this as some
                // recordings are still coming through when showing as false in Posthog.
                posthog.isFeatureEnabled('isSessionRecordingEnabled') === true
            ) {
                const featureFlagPayload = posthog.getFeatureFlagPayload(
                    'isSessionRecordingEnabled',
                );
                const timeout = featureFlagPayload?.timeoutInMs;

                setTimeout(() => posthog.startSessionRecording(), timeout || 0);
            }

            isInitialFeatureFlagLoad = false;
        });

        let isEmployee = $cookies?.get('usx_emp');

/////////////////////////////////////////
//////////////////////////
//////////////////

        const userType = isEmployee ? 'employee' : 'customer';

        /**
         * Helper function for getting the missing environment variable error instance.
         *
         * @param  {string} variableName Missing environment variable name.
         *
         * @return {Error}
         */
        const getMissingEnvironmentVariableError = variableName =>
            new Error(
                `Missing the \`${variableName}\` environment variable. Cannot initiate PostHog.`,
            );

        const requiredEnvVars = [
            'postHogApiKey',
            'postHogHost',
            'sentryDSN',
            'postHogOrganizationName',
        ];

        for (const envVar of requiredEnvVars) {
            if (!$config[envVar]) {
                logError(getMissingEnvironmentVariableError(envVar));
            }
        }

        // Initalize PostHog
        posthog.init($config.postHogApiKey, {
            api_host: $config.postHogHost,
            capture_pageview: false,
            disable_session_recording: true,
        });

        posthog.setPersonProperties({ user_type: userType });

        // If your flag depends on user properties that are not instantly available and you want
        // to make them available without waiting for server delays, send these properties before evaluating flags.
        // More info: https://posthog.com/docs/libraries/js#overriding-server-properties
        posthog.setPersonPropertiesForFlags({
            user_type: userType,
            $initial_current_url: window?.location?.href,
        });

        // Inject PostHog into the application and make it available via `this.$posthog` or `app.$posthog`.
        inject('posthog', posthog);

        // Expose posthog to window for GTM. This is a client only plugin, but let's leave this check to prevent `window` errors on server.
        window.posthog = window.posthog || posthog;

        const Sentry = await import('@sentry/browser');

        const posthogOrganizationName = $config.postHogOrganizationName;
        const sentryProjectId = (posthogOrganizationName || '').split('/').pop();

        Sentry.init({
            dsn: $config.sentryDSN,
            integrations: [
                new Integrations.BrowserTracing(),
                new posthog.SentryIntegration(posthog, posthogOrganizationName, sentryProjectId),
            ],
        });

        // Make sure that pageviews are captured with each route change.
        app.router.afterEach(to => {
            Vue.nextTick(async () => {
                const { getPostHogGlobalEventPropertiesFromDataLayer } = await import(
                    '@utilities/getPostHogGlobalEventPropertiesFromDataLayer'
                );

                const existingGtmDataLayerPostHogGlobalEventProperties =
                    (await getPostHogGlobalEventPropertiesFromDataLayer($config)) || {};

                posthog.capture('$pageview', {
                    $current_url: ($config?.baseUrl || '') + to.fullPath,
                    ...existingGtmDataLayerPostHogGlobalEventProperties,
                });
            });
        });
    }
};
