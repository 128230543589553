import { getProductMedia } from '@graphql/operations/productMedia';
import { getAbsoluteUrlPath } from '@utilities/getAbsoluteUrlPath';

export const mutations = {
    /**
     * Mutate the `selected` state with an updated object.
     *
     * @param  {Object}  parent        The parent resolver instance.
     * @param  {Obect}   item          The selected media item.
     * @param  {Object}  options.cache The Apollo Client cache instance.
     */
    setSelectedProductMedia: (parent, { item }, { cache }) => {
        const data = cache.readQuery({
            query: getProductMedia,
        });

        data.productMedia.selected = item;

        cache.writeData({
            data,
        });
    },

    /**
     * Mutate the `productMedia.items` state.
     *
     * @param  {Object}  parent         The parent resolver instance.
     * @param  {Array}   items          The media items.
     * @param  {Object}  options.cache  The Apollo Client cache instance.
     */
    setProductMediaItems: (parent, { items }, { cache }) => {
        const data = cache.readQuery({
            query: getProductMedia,
        });

        // Add image base URL to the image key and set typename for each object
        data.productMedia.items = items.map(item => {
            return {
                ...item,
                image: item.isVideo ? item.image : getAbsoluteUrlPath(item.image),
                __typename: 'ProductMediaItem',
            };
        });

        // Set the selected media to the first one by default
        data.productMedia.selected = data.productMedia.items[0];

        cache.writeData({
            data,
        });
    },
};

export const initialCacheState = {
    productMedia: {
        selected: {
            __typename: 'ProductMediaItem',
            altTag: '',
            defaultAltTag: '',
            caption: '',
            image: '',
            rank: 0,
            isVideo: false,
            source: '',
        },
        items: [],
        __typename: 'ProductMedia',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
///////////////////////////////
///////////////////////////
//////////////////////////////////
////////////////////////////
//////////////////////////
//////////////////////
/////////////////////////////
///////////////////////////
/////////

///////////////////////////
///////////////////////////////////////
/////////////////////////////////////
/////////
//////
//

////////////////////////////////////////////////////////////////

////////////////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//
//////////
