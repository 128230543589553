export const genericOutOfStockErrorMessage = 'Product is no longer in stock and can not be added to your cart at this time.';

export const genericCartItemRemovalErrorMessage
    = 'Some items have been removed from your shopping cart due to limited stock and availability.';

/**
 * Get a new Error type instance with the public message flag.
 *
 * @param  {string} message The error message.
 *
 * @return {Error}
 */
export const getPublicMessageErrorInstance = (message = genericOutOfStockErrorMessage) => {
    const errorInstance = new Error(message);

    errorInstance.hasPublicMessage = true;

    return errorInstance;
};
