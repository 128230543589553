import { redirectWithQueryString } from '@utilities/redirectWithQueryString';

/**
 * Verify that the user is not authenticated before allowing the page view.
 *
 * @param  {Object}   options.app      The application context instance.
 * @param  {Function} options.redirect The redirect helper function.
 */
export default ({ app, redirect, route }) => {
    const redirectTo = route.query?.redirectTo;

    // Redirect to the redirect/account page for authenticated users
    if (app.$apolloHelpers.getToken()) {
        redirectWithQueryString(
            redirect,
            redirectTo || '/account/quick-reorder',
            route,
            app.$cookies,
            redirectTo ? ['redirectTo'] : [],
        );
    } else if (redirectTo) {
        (async () => {
            const [
                { CPAP_STORAGE_KEY_PREFIX, AUTH_REDIRECT_KEY },
                { setCookie },
            ] = await Promise.all([
                import('@constants/localStorageKeys'),
                import('@utilities/cookies'),
            ]);

            // Store the attempted route to return the user to after a successful login
            // Cannot use a store due to a bug in Nuxt with middleware redirects
            // See https://github.com/nuxt/nuxt.js/issues/5576
            setCookie(app.$cookies, CPAP_STORAGE_KEY_PREFIX + AUTH_REDIRECT_KEY, redirectTo);

            redirectWithQueryString(redirect, '/logout', route, app.$cookies);
        })();
    }
};
