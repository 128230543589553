import { getCheckoutState } from '@graphql/operations/checkout';

export const mutations = {
    /**
     * Set the client-only checkout states.
     *
     * @param  {Object}  response                                    The mutation response (not applicable).
     * @param  {Boolean} options.isBillingSameAsShipping             The flag to toggle billing address same as shipping address.
     * @param  {Object}  options.isUpdatingShippingAddress           The updating shipping address mutation flag.
     * @param  {Object}  options.isValidatingShippingAddress         The processing and validating shipping address mutation flag.
     * @param  {Object}  options.hasValidShippingAddress             The validated shipping address flag.
     * @param  {Object}  options.isProcessingPrescriptionUpload      The processing RX upload mutation flag.
     * @param  {Object}  options.isRefetchingShippingMethods         Refetching shipping methods form server mutation flag.
     * @param  {Object}  options.isProcessingShippingMethod          The processing shipping method mutation flag.
     * @param  {Object}  options.hasPresetCartSessionShippingMethod  Flag that the selected shiping method has been preset.
     * @param  {Object}  options.isCartSessionShippingMethodOutdated Flag that the cart session selected shiping method is outdated.
     * @param  {Object}  options.isUpdatingPromoCode                 Flag that the cart session promo code is being updated.
     * @param  {Object}  options.isRemovingPromoCode                 Flag that the cart session promo code is being removed.
     * @param  {Object}  options.isPrescriptionOptionComplete        If completed the prescription options
     * @param  {Object}  options.selectedPrescriptionOptionLabel     The selected rx option
     * @param  {Object}  options.prescriptions                       The prescription files meta data.
     * @param  {Object}  options.pendingPrescriptions                The prescription files that have not been uploaded yet.
     * @param  {Object}  options.isUploadingPrescriptions            Flag that indicates that we are uploading the prescriptions.
     * @param  {Object}  options.isPrescriptionUploadComplete        Flag that indicates that we are uploading the prescriptions.
     * @param  {Object}  options.doctorContactDetails                Details about the selected doctor
     * @param  {Object}  options.previewShippingPrice                The preview shipping price before being saved to session.
     * @param  {Object}  options.cache                               The Apollo Client cache instance.
     *
     * @return {Object}
     */
    setCheckoutState: (
        response,
        {
            isBillingSameAsShipping,
            isUpdatingShippingAddress,
            isValidatingShippingAddress,
            hasValidShippingAddress,
            isProcessingPrescriptionUpload,
            isUpdatingCartVariantSelection,
            isRefetchingShippingMethods,
            isProcessingShippingMethod,
            hasPresetCartSessionShippingMethod,
            isCartSessionShippingMethodOutdated,
            isUpdatingPromoCode,
            isRemovingPromoCode,
            isPrescriptionOptionComplete,
            selectedPrescriptionOptionLabel,
            updatedAddressId,
            prescriptions,
            pendingPrescriptions,
            isUploadingPrescriptions,
            isPrescriptionUploadComplete,
            doctorContactDetails,
            couponCode,
            previewShippingPrice,
        },
        { cache },
    ) => {
        const data = cache.readQuery({
            query: getCheckoutState,
        });

        if (typeof isBillingSameAsShipping !== 'undefined') {
            data.checkout.isBillingSameAsShipping = isBillingSameAsShipping;
        }

        if (typeof isUpdatingShippingAddress !== 'undefined') {
            data.checkout.isUpdatingShippingAddress = isUpdatingShippingAddress;
        }

        if (typeof isValidatingShippingAddress !== 'undefined') {
            data.checkout.isValidatingShippingAddress = isValidatingShippingAddress;
        }

        if (typeof hasValidShippingAddress !== 'undefined') {
            data.checkout.hasValidShippingAddress = hasValidShippingAddress;
        }

        if (typeof isProcessingPrescriptionUpload !== 'undefined') {
            data.checkout.isProcessingPrescriptionUpload = isProcessingPrescriptionUpload;
        }

        if (typeof isUpdatingCartVariantSelection !== 'undefined') {
            data.checkout.isUpdatingCartVariantSelection = isUpdatingCartVariantSelection;
        }

        if (typeof isRefetchingShippingMethods !== 'undefined') {
            data.checkout.isRefetchingShippingMethods = isRefetchingShippingMethods;
        }

        if (typeof isProcessingShippingMethod !== 'undefined') {
            data.checkout.isProcessingShippingMethod = isProcessingShippingMethod;
        }

        if (typeof hasPresetCartSessionShippingMethod !== 'undefined') {
            data.checkout.hasPresetCartSessionShippingMethod = hasPresetCartSessionShippingMethod;
        }

        if (typeof isCartSessionShippingMethodOutdated !== 'undefined') {
            data.checkout.isCartSessionShippingMethodOutdated = isCartSessionShippingMethodOutdated;
        }

        if (typeof isUpdatingPromoCode !== 'undefined') {
            data.checkout.isUpdatingPromoCode = isUpdatingPromoCode;
        }

        if (typeof isRemovingPromoCode !== 'undefined') {
            data.checkout.isRemovingPromoCode = isRemovingPromoCode;
        }

        if (typeof isPrescriptionOptionComplete !== 'undefined') {
            data.checkout.isPrescriptionOptionComplete = isPrescriptionOptionComplete;
        }

        if (typeof selectedPrescriptionOptionLabel !== 'undefined') {
            data.checkout.selectedPrescriptionOptionLabel = selectedPrescriptionOptionLabel || '';
        }

        if (typeof updatedAddressId !== 'undefined') {
            data.checkout.updatedAddressId = updatedAddressId;
        }

        if (prescriptions) {
            data.checkout.prescriptions = prescriptions || [];
        }

        if (pendingPrescriptions) {
            data.checkout.pendingPrescriptions = (pendingPrescriptions || []).map(
                pendingPrescription => ({
                    __typename: 'PendingPrescription',
                    ...pendingPrescription,
                }),
            );
        }

        if (typeof isUploadingPrescriptions !== 'undefined') {
            data.checkout.isUploadingPrescriptions = isUploadingPrescriptions;
        }

        if (typeof isPrescriptionUploadComplete !== 'undefined') {
            data.checkout.isPrescriptionUploadComplete = isPrescriptionUploadComplete;
        }

        if (doctorContactDetails) {
            data.checkout.doctorContactDetails = {
                __typename: 'DoctorContactDetails',
                ...doctorContactDetails,
            };
        }

        if (typeof couponCode !== 'undefined') {
            data.checkout.couponCode = couponCode || '';
        }

        if (typeof previewShippingPrice !== 'undefined') {
            data.checkout.previewShippingPrice = previewShippingPrice;
        }

        cache.writeData({ data });

        return data;
    },

    /**
     * Remove the doctor contact details in checkout state from the cache.
     *
     * @param  {Object}  response      The mutation response (not applicable).
     * @param  {Object}  variables     The mutation variables if applicable.
     * @param  {Object}  options.cache The Apollo Client cache instance.
     *
     * @return {Object}
     */
    removeDoctorContactDetails(response, variables, { cache }) {
        const data = cache.readQuery({
            query: getCheckoutState,
        });

        data.checkout.doctorContactDetails = {
            doctorid: 0,
            birthday: '',
            agree: 0,
            doctorStatusId: 0,
            name: '',
            phone: '',
            __typename: 'DoctorContactDetails',
        };

        cache.writeData({ data });

        return data;
    },

    /**
     * Remove the coupon code in checkout state from the cache.
     *
     * @param  {Object}  response      The mutation response (not applicable).
     * @param  {Object}  variables     The mutation variables if applicable.
     * @param  {Object}  options.cache The Apollo Client cache instance.
     *
     * @return {Object}
     */
    removeDeferredCouponCodeFromCheckoutState(response, variables, { cache }) {
        const data = cache.readQuery({
            query: getCheckoutState,
        });

        data.checkout.couponCode = '';

        cache.writeData({ data });

        return data;
    },
};

export const initialCacheState = {
    checkout: {
        isBillingSameAsShipping: true,
        isUpdatingShippingAddress: false,
        isValidatingShippingAddress: false,
        hasValidShippingAddress: false,
        isProcessingPrescriptionUpload: false,
        isUpdatingCartVariantSelection: false,
        isRefetchingShippingMethods: false,
        isProcessingShippingMethod: false,
        hasPresetCartSessionShippingMethod: false,
        isCartSessionShippingMethodOutdated: false,
        isUpdatingPromoCode: false,
        isRemovingPromoCode: false,
        isPrescriptionOptionComplete: false,
        selectedPrescriptionOptionLabel: '',
        updatedAddressId: null,
        prescriptions: null,
        pendingPrescriptions: null,
        isUploadingPrescriptions: false,
        isPrescriptionUploadComplete: false,
        doctorContactDetails: null,
        couponCode: '',
        previewShippingPrice: -1,
        __typename: 'ClientCheckout',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
////////////////////
///////////////////////////////////
//////////////////////////////
////////////////////////////
///////////////////////////
////////////////////////////////////
////////////////////////
/////////////////////////
/////////

////////////////////
///////////////////////////
///////////////////////
/////////////////////////////
/////////

////////////////////
//////////////////////////////////
/////////////////////////////
///////////////////////////
/////////

/////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////
///////////////////////////////////////
/////////
//////
//

//////////////////////////////////////////////////////////////

////////////////////////////////////
/////
/////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////
///////////////////////////////////////
/////////////////////////
//////
//
//////////
