/**
 * Set the customer into the appropriate split testing bucket, if defined.
 *
 * @param  {Object} options.app    The app context object.
 * @param  {Object} options.req    The Nuxt request instance.
 * @param  {Object} options.query  The Nuxt parsed query string as a `key: value` paired object.
 */
export default async ({
    app,
    req,
/////////////////////////////////////
//////////
//////////////
}) => {
    // Even though this will only execute on the server, A/B testing uses the Vary header
    // at Fastly to ensure that the same split testing group will get the same cached
    // response from the server generation, therefore it's safe to execute this on the
    // server and cache the result in the Nuxt hydration context for the client user.
    if (process.server) {
        const { addSplitTest } = await import('@graphql/operations/splitTesting');

        const apollo = app.apolloProvider.defaultClient;

/////////////////////////////////////////
////////////////////
/////////////////////////////////
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
/////////
//////////////////

        const splitTestQueries = Object.entries(req.headers || {})
            // Map any A/B testing headers into storable Apollo mutations for local state
            .map(([key, value]) => {
                const [experimentId, variationId] = String(value).split('.');

                return key.startsWith('x-ab-') && variationId
                    ? apollo.mutate({
                          mutation: addSplitTest,
                          variables: {
                              label: key.substring(5),
                              experimentId,
                              variationId: Number(variationId),
                          },
                      })
                    : null;
            })
            // Filter out any null values
            .filter(splitTest => splitTest);

        // Determine if any split test Apollo query promises exist
        if (splitTestQueries?.length) {
            // Await for the local store to be set
            await Promise.all(splitTestQueries);
        }
    }
};
