import { gql } from 'graphql-tag';

const dialogParameterTypes = `
    $name: String!,
    $data: JSON,
    $options: JSON,
    $props: JSON,
    $emissionHandlers: JSON,
    $isPersistent: Boolean,
    $virtualPageView: JSON,
`;

const dialogParameters = `
    name: $name,
    data: $data,
    options: $options,
    props: $props,
    emissionHandlers: $emissionHandlers,
    isPersistent: $isPersistent,
    virtualPageView: $virtualPageView,
`;

export const getDialog = gql`
    query getDialog {
        dialog @client {
            isOpen
            name
            data
        }
    }
`;

export const openDialog = gql`
    mutation openDialog(
        ${dialogParameterTypes}
    ) {
        openDialog(
            ${dialogParameters}
        ) @client
    }
`;

export const closeDialog = gql`
    mutation closeDialog {
        closeDialog @client
    }
`;

export const replaceDialog = gql`
    mutation replaceDialog(
        ${dialogParameterTypes}
    ) {
        replaceDialog(
            ${dialogParameters}
        ) @client
    }
`;
