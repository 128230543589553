import { gql } from 'graphql-tag';

// Look for files in the local GraphQL resolvers directory, include all subdirectories, but only include .js files
// https://webpack.js.org/guides/dependency-management/#require-context
const requiredResolverFiles = require
    .context('./../../src/graphql/resolvers', true, /[\w]+\.js/)
    .keys()
    // filter any unit tests from the resolvers directory
    .filter(file => !file.includes('.unit.js'));

// Require the resolver for each matching file name
const resolvers = requiredResolverFiles.map(file =>
    require('./../../src/graphql/resolvers/' + file.substring(2)),
);

const Query = {};
const Mutation = {};
let initialCacheState = {}; // eslint-disable-line import/no-mutable-exports
let typeDefs = ''; // eslint-disable-line import/no-mutable-exports

// Iterate through each resolver
resolvers.forEach(resolver => {
    // Set any query resolvers by query key
    Object.entries(resolver.queries || {}).forEach(([key, value]) => (Query[key] = value));

    // Set any mutation resolvers by query key
    Object.entries(resolver.mutations || {}).forEach(([key, value]) => (Mutation[key] = value));

    // Merge into the initial cache state
    initialCacheState = {
        ...initialCacheState,
        ...(resolver.initialCacheState || {}),
    };
});

// Define the type definitions
// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////
/////////////
//////////////
/////////////////
//

///////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////
///////
///

//////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////
//////////
///////////////

//////////////////////////

///////////////////////
///////////////////////////////
/////

//////////////////////////
//////////////////////////////////
/////
 
/////////
//////////

export { typeDefs, initialCacheState, Query, Mutation };
