import { ApolloLink } from '@apollo/client/link/core';
import { isExpectedOperationType } from '@utilities/apollo/isExpectedOperationType';

/**
 * Create an Apollo link instance for adding the preview header to the CMS API query.
 *
 * @return {ApolloLink}
 */
export const createCmsPreviewHeaderLink = ({ app }) => {
    return new ApolloLink((operation, forward) => {
        const isPreviewing = app.$previewMode?.isPreviewing;

        // Only instantiate Apollo link observer if this is a query
        if (isPreviewing && isExpectedOperationType(operation, 'query')) {
            const isCmsQuery = (operation?.query?.definitions || []).some(
                definition => definition?.name?.value === 'getCmsContent',
            );

            if (isCmsQuery) {
                operation.setContext({
                    headers: {
                        'X-Preview': '1',
                    },
                });
            }
        }

        return forward(operation);
    });
};
