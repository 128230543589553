import { gql } from 'graphql-tag';

export const getProductMedia = gql`
    query getProductMedia {
        productMedia @client {
            selected {
                altTag
                defaultAltTag
                caption
                image
                rank
                isVideo
                source
            }
            items {
                altTag
                defaultAltTag
                caption
                image
                rank
                isVideo
                source
            }
        }
    }
`;

export const setSelectedProductMedia = gql`
    mutation setSelectedProductMedia($item: ProductMediaItem!) {
        setSelectedProductMedia(item: $item) @client
    }
`;

export const setProductMediaItems = gql`
    mutation setProductMediaItems($items: [ProductMediaItem]) {
        setProductMediaItems(items: $items) @client
    }
`;
