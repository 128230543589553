import { getFlashMessageState } from '@graphql/operations/flashMessage';

let uniqueCount = 0;

// Timeout types
const flashTimeout = {
    errorTimeout: null,
    successTimeout: null,
    informationTimeout: null,
};

/**
 * Reusable method to push new messages into their respective arrays
 *
 * @param   {Object}   flashMessage  Apollo cache object
 * @param   {Object}   newMessage    The new message object
 * @param   {String}   type          The type of flash message
 *
 * @return  void
 */
const pushNewFlashMessage = ({ flashMessage }, newMessage, type) =>
    flashMessage[`${type}Messages`].push(newMessage);

export const mutations = {
    /**
     * Set the application's flash message state.
     *
     * @param  {Object}  response            The mutation response (not applicable).
     * @param  {String}  options.message     The flash message.
     * @param  {String}  options.type        The flash type.
     * @param  {Number}  options.timeout     The flash timeout.
     * @param  {Object}  options.cache       The Apollo Client cache instance.
     */
    setFlashMessageState: (response, { message, type, timeout }, { cache }) => {
        const data = cache.readQuery({
            query: getFlashMessageState,
        });

        if (message && type) {
            uniqueCount += 1;

            pushNewFlashMessage(
                data,
                {
                    body: message,
                    id: uniqueCount,
                    __typename: `FlashMessageData`,
                },
                type,
            );
        }

        // Write the update data to Apollo cache
        cache.writeData({ data });

        // Is there a timeout value set?
        if (timeout) {
            // Clear out any timeout set
            if (flashTimeout[`${type}Timeout`]) {
                clearTimeout(flashTimeout[`${type}Timeout`]);
            }

            // Define the timeout
            flashTimeout[`${type}Timeout`] = setTimeout(() => {
                // Reset to the initial flash message state once the timeout has completed
                mutations.resetFlashMessageState(null, {}, { cache });
            }, timeout);
        }
    },

    /**
     * Resets the application's flash message state.
     *
     * @param  {Object}  response       The mutation response (not applicable).
     * @param  {Boolean} variables      The mutation variables (not applicable).
     * @param  {Object}  options.cache  The Apollo Client cache instance.
     */
    resetFlashMessageState: (response, variables, { cache }) => {
        const data = cache.readQuery({
            query: getFlashMessageState,
        });

        // Reset all message arrays back to initial state
        data.flashMessage.errorMessages = [];
        data.flashMessage.successMessages = [];
        data.flashMessage.informationMessages = [];

        // Write the updated data to Apollo cache
        cache.writeData({ data });
    },
};

export const initialCacheState = {
    flashMessage: {
        errorMessages: [],
        successMessages: [],
        informationMessages: [],
        __typename: 'FlashMessage',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
///////////////////////////////
///////////////////////
/////////////////////////
/////////

///////////////////////////
//////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////
//////
//

////////////////////////////////////////////////////////////////

////////////////////////////////////
//////////////////////////
/////////////////////
////////////////////////
/////////////////////////
/////////////////////
///////////////////////////////////////////
//
//////////
