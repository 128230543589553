import { gql } from 'graphql-tag';

export const getFlashMessageState = gql`
    query getFlashMessageState {
        flashMessage @client {
            errorMessages {
                id
                body
            }
            successMessages {
                id
                body
            }
            informationMessages {
                id
                body
            }
        }
    }
`;

export const setFlashMessageState = gql`
    mutation setFlashMessageState($message: String!, $type: String!, $timeout: Integer) {
        setFlashMessageState(message: $message, type: $type, timeout: $timeout) @client
    }
`;

export const resetFlashMessageState = gql`
    mutation resetFlashMessageState {
        resetFlashMessageState @client
    }
`;
