import Vue from 'vue';

/**
 * Initialize LaunchDarkly state via the node SDK.
 *
 * @param {object} options.app               The app context object.
 * @param {object} options.route             The contextual route data.
 * @param {object} options.beforeNuxtRender  Nuxt specific method to update `__NUXT__` variable rendered on client-side.
 * @param {object} options.nuxtState         Nuxt specific method to get the nuxt state on client-side before hydration
 * @param {object} options.$config           The Nuxt public runtime configuration options.
 * @param {object} inject                    Inject values into the app instance.
 */
export default ({ app }, inject) => {
    // Create the observable reactive property variable.
    const launchDarklyStore = Vue.observable({
        flags: {
            isAdvancedReviewsFeaturesEnabled: true,
            isAirMiniDialogActive: true,
            isConsolidatedLoginSignupActive: false,
            isExternalBusinessRatingActive: true,
            isFixedFooterMobileMenuActive: false,
            isPersistentCompatibleFilterActive: false,
            isPlpProductCardCallToActionActive: false,
            isPlpTwoColGridActive: true,
            isUpsellTextCalloutActive: false,
            isUpsellTextTooltipActive: false,
            previewModeEnabled: false,
            isOneTimeLoginEnabled: false,
            isContactFlyoutRandomCsrActive: false,
        },
    });

    inject('launchDarkly', launchDarklyStore);
};
