/**
 * Force a hard refresh for the /blog/ endpoint which will load the separate WordPress site.
 *
 * @param  {Object} options.route    The route instance.
 * @param  {Object} options.redirect The redirect function.
 */
export default ({ route, redirect }) => {
    // Determine if the route path starts with the blog endpoint
    if (route.path.startsWith('/blog')) {
        const redirectTo = route.fullPath;

        // Determine redirect method based on environment
        if (process.server) {
            redirect(redirectTo);
        } else {
            window.location.assign(redirectTo);
        }
    }
};
