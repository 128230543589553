// Grayscale Colors
export const CPAP_THEME_COLOR_WHITE = '#FFFFFF';
export const CPAP_THEME_COLOR_GRAY_1 = '#FAFAFA';
export const CPAP_THEME_COLOR_GRAY_2 = '#F5F5F5';
export const CPAP_THEME_COLOR_GRAY_3 = '#EEEEEE';
export const CPAP_THEME_COLOR_GRAY_4 = '#E0E0E0';
export const CPAP_THEME_COLOR_GRAY_5 = '#BDBDBD';
export const CPAP_THEME_COLOR_GRAY_6 = '#9E9E9E';
export const CPAP_THEME_COLOR_GRAY_7 = '#757575';
export const CPAP_THEME_COLOR_GRAY_8 = '#616161';
export const CPAP_THEME_COLOR_GRAY_9 = '#424242';
export const CPAP_THEME_COLOR_GRAY_10 = '#212121';
export const CPAP_THEME_COLOR_BLACK = '#000000';

// Blue Colors
export const CPAP_THEME_COLOR_BLUE_TINT_1 = '#239FDE';
export const CPAP_THEME_COLOR_BLUE_TINT_2 = '#27B1F7';
export const CPAP_THEME_COLOR_BLUE = '#1F8DC5';
export const CPAP_THEME_COLOR_BLUE_SHADE_1 = '#1B7AAB';
export const CPAP_THEME_COLOR_BLUE_SHADE_2 = '#176891';

// Green Colors
export const CPAP_THEME_COLOR_GREEN_TINT_1 = '#9DDB7F';
export const CPAP_THEME_COLOR_GREEN_TINT_2 = '#C2F1AB';
export const CPAP_THEME_COLOR_GREEN = '#6CBD45';
export const CPAP_THEME_COLOR_GREEN_SHADE_1 = '#39960C';
export const CPAP_THEME_COLOR_GREEN_SHADE_2 = '#327214';

// MediaQuery Breakpoints
export const CPAP_THEME_MQ_BREAKPOINT_XS = 380;
export const CPAP_THEME_MQ_BREAKPOINT_SM = 576;
export const CPAP_THEME_MQ_BREAKPOINT_MD = 768;
export const CPAP_THEME_MQ_BREAKPOINT_LG = 992;
export const CPAP_THEME_MQ_BREAKPOINT_XL = 1200;
export const CPAP_THEME_MQ_BREAKPOINT_XXL = 1440;

export const CPAP_THEME_MQ_BREAKPOINTS_KEYED = {
    xs: CPAP_THEME_MQ_BREAKPOINT_XS,
    sm: CPAP_THEME_MQ_BREAKPOINT_SM,
    md: CPAP_THEME_MQ_BREAKPOINT_MD,
    lg: CPAP_THEME_MQ_BREAKPOINT_LG,
    xl: CPAP_THEME_MQ_BREAKPOINT_XL,
    xxl: CPAP_THEME_MQ_BREAKPOINT_XXL,
};

// Default font sizes
export const CPAP_THEME_DEFAULT_FONT_SIZE_PX = 14;
