import { gql } from 'graphql-tag';

export const CustomizableOptionInterfaceFragment = gql`
    fragment CustomizableOptionInterfaceFragment on CustomizableOptionInterface {
        uid # Required for the unique Apollo cache identifier
        option_id
        title
        ... on CustomizableDropDownOption {
            value {
                uid # Required for the unique Apollo cache identifier
                option_type_id
            }
        }
    }
`;
