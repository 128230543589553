import { getHomeSleepTestState } from '@graphql/operations/homeSleepTest';

export const mutations = {
    /**
     * Set the user's home sleep test state.
     *
     * @param  {Object}  parent                The parent resolver instance.
     * @param  {String}  options.isTakingTest  Taking test flag.
     * @param  {Object}  context.cache         The Apollo Client cache instance.
     */
    setHomeSleepTestState: (parent, { isTakingTest }, { cache }) => {
        const data = cache.readQuery({
            query: getHomeSleepTestState,
        });

        data.homeSleepTest.isTakingTest = isTakingTest || false;

        cache.writeData({
            data,
        });
    },
};

export const initialCacheState = {
    homeSleepTest: {
        isTakingTest: false,
        __typename: 'HomeSleepTest',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
////////////////////////////
//////////////////////////////////
/////////
//////
//

//////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////
//////////
