import { gql } from 'graphql-tag';
import { BreadcrumbsFragment } from './breadcrumbs';
import { ConfigurableAttributeOptionFragment } from './ConfigurableAttributeOptionFragment';
import { ConfigurableProductOptionsFragment } from './ConfigurableProductOptionsFragment';
import { CustomizableOptionInterfaceFragment } from './CustomizableOptionInterfaceFragment';
import { SubscriptionIntervalFragment } from './SubscriptionIntervalFragment';
import { accessoryFields } from './../templates/accessoryFields';

export const ListingProductFragment = gql`
    fragment ListingProductFragment on ProductInterface {
        uid # Required for the Apollo cache unique fragment identifier
        id # Required for the Apollo cache unique fragment identifier
        sku
        name
        short_name
        url_key
        main_image
        max_sale_qty
        attribute_set_label
        # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
        product_availability
        product_availability_label
        rx_required
        # The 'free_shipping_options' key must always be included for 'free_shipping_options_label' to return proper data
        free_shipping_options
        free_shipping_options_label
        is_returnable
        rating_summary
        review_count
        icon_sale_flag
        displayable_in_stock
        displayable_allow_backorders
        custom_badge
        upsell_text
        plp_bullets {
            bullet
        }
        price_tiers {
            quantity
        }
        # The 'manufacturer' key must always be included for 'brand' to return proper data
        manufacturer
        brand {
            id # Required for the Apollo cache unique fragment identifier
            label
        }
        advanced_pricing {
            msrp
            msrp_display_actual_price_type
        }
        price_range {
            minimum_price {
                final_price {
                    value
                }
                regular_price {
                    value
                }
                discount {
                    amount_off
                    percent_off
                }
                promotion_discount @include(if: $hasPromoCode) {
                    applies_to_product
                    amount_off_regular_price
                    percent_off_regular_price
                    price {
                        value
                    }
                }
            }
        }
        ... on ConfigurableProduct {
            configurable_options {
                ...ConfigurableProductOptionsFragment
            }
            variants {
                attributes {
                    ...ConfigurableAttributeOptionFragment
                }
                product {
                    uid # Required for the Apollo cache unique fragment identifier
                    id # Required for the Apollo cache unique fragment identifier
                    sku
                    displayable_in_stock
                    displayable_allow_backorders
                    # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                    product_availability
                    product_availability_label
                    # The below fields are needed for proper optimistic add-to-cart responses
                    name
                    short_name
                    url_key
                    main_image
                    price_tiers {
                        quantity
                        final_price {
                            value
                        }
                    }
                    price_range {
                        minimum_price {
                            final_price {
                                value
                            }
                            regular_price {
                                value
                            }
                            discount {
                                amount_off
                                percent_off
                            }
                        }
                    }
                    advanced_pricing {
                        msrp
                        msrp_display_actual_price_type
                    }
                }
            }
        }
        ... on PhysicalProductInterface {
            weight
        }
    }
    ${ConfigurableAttributeOptionFragment}
    ${ConfigurableProductOptionsFragment}
`;

// this cannot be put into query that has a higher level pulling ConfigurableProduct attributes
export const ReplacementPartsFragment = gql`
    fragment ReplacementPartsFragment on ProductInterface {
        uid # Required for the Apollo cache unique fragment identifier
        id # Required for the Apollo cache unique fragment identifier
        sku
        url_key
        replacementpart_products {
            # We cannot use a fragment here due to a Magento bug. This will be fixed in 2.4.2.
            # See https://github.com/magento/magento2/issues/29769
            # Track issue at https://github.com/magento/magento2/projects/27#card-47751648
            ${accessoryFields}
            attribute_set_label
        }
    }
`;

export const ProductDetailFragment = gql`
    fragment ProductDetailFragment on ProductInterface {
        ...ListingProductFragment
        categories {
            id # Required for the Apollo cache unique fragment identifier
            name
            url_path
            plp_url
            breadcrumbs {
                ...BreadcrumbsFragment
            }
        }
        meta_title
        meta_description
        canonical_url
        noindex_flag
        media_gallery {
            disabled
            label
            position
            url
        }
        all_image_json
        sizing_guide
        product_overview
        general_specs_information
        promotional_text
        videos_json
        bundle_sku
        price_tiers {
            quantity
            final_price {
                value
            }
        }
        rating_breakdown {
            id # Required for the Apollo cache unique fragment identifier
            rating_count
            value
        }
        subscription_recommended_interval_id
        subscriptions {
            ...SubscriptionIntervalFragment
        }
        price_range {
            minimum_price {
                subscription_discount {
                    price {
                        value
                    }
                    amount_off_final_price
                    percent_off_final_price
                    amount_off_regular_price
                    percent_off_regular_price
                }
            }
        }
        ... on CustomizableProductInterface {
            options {
                ...CustomizableOptionInterfaceFragment
            }
        }
        ... on ConfigurableProduct {
            configurable_options {
                ...ConfigurableProductOptionsFragment
            }
            variants {
                product {
                    uid # Required for the Apollo cache unique fragment identifier
                    id # Required for the Apollo cache unique fragment identifier
                    sku
                    is_returnable
                    displayable_in_stock
                    displayable_allow_backorders
                    # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                    product_availability
                    product_availability_label
                    # The below fields are needed for proper optimistic add-to-cart responses
                    name
                    url_key
                    main_image
                    price_tiers {
                        quantity
                        final_price {
                            value
                        }
                    }
                    price_range {
                        minimum_price {
                            final_price {
                                value
                            }
                            regular_price {
                                value
                            }
                            discount {
                                amount_off
                                percent_off
                            }
                        }
                    }
                    advanced_pricing {
                        msrp
                        msrp_display_actual_price_type
                    }
                }
            }
        }
        ... on BundleProduct {
            dynamic_price
            items {
                uid # Required for the Apollo cache unique fragment identifier
                sku
                title
                required
                options {
                    uid # Required for the Apollo cache unique fragment identifier
                    quantity
                    is_default
                    price
                    label
                    product {
                        uid # Required for the Apollo cache unique fragment identifier
                        id # Required for the Apollo cache unique fragment identifier
                        name
                        url_key
                        short_name
                        main_image
                        product_overview
                        rating_summary
                        review_count
                        rx_required # Used for optimistic response
                        # The 'manufacturer' key must always be included for 'brand' to return proper data
                        manufacturer
                        brand {
                            id
                            label
                        }
                        price_range {
                            minimum_price {
                                final_price {
                                    value
                                }
                                regular_price {
                                    value
                                }
                                discount {
                                    amount_off
                                    percent_off
                                }
                                promotion_discount @include(if: $hasPromoCode) {
                                    price {
                                        value
                                    }
                                }
                            }
                        }
                        # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                        product_availability
                        product_availability_label
                        displayable_in_stock
                        displayable_allow_backorders
                        ... on ConfigurableProduct {
                            configurable_options {
                                ...ConfigurableProductOptionsFragment
                            }
                            variants {
                                attributes {
                                    ...ConfigurableAttributeOptionFragment
                                }
                                product {
                                    uid # Required for the Apollo cache unique fragment identifier
                                    id # Required for the Apollo cache unique fragment identifier
                                    sku
                                    displayable_in_stock
                                    displayable_allow_backorders
                                    # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                                    product_availability
                                    product_availability_label
                                    price_tiers {
                                        quantity
                                        final_price {
                                            value
                                        }
                                    }
                                    price_range {
                                        minimum_price {
                                            final_price {
                                                value
                                            }
                                            regular_price {
                                                value
                                            }
                                            discount {
                                                amount_off
                                                percent_off
                                            }
                                        }
                                    }
                                    advanced_pricing {
                                        msrp
                                        msrp_display_actual_price_type
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${ListingProductFragment}
    ${BreadcrumbsFragment}
    ${ConfigurableProductOptionsFragment}
    ${ConfigurableAttributeOptionFragment}
    ${CustomizableOptionInterfaceFragment}
    ${SubscriptionIntervalFragment}
`;

export const ProductCategoriesFragment = gql`
    fragment ProductCategoriesFragment on CategoryInterface {
        id # Required for the Apollo cache unique fragment identifier
        uid
        name
        breadcrumbs {
            category_level
            category_id
            category_name
        }
    }
`;

export const ComparableProductFragment = gql`
    fragment ComparableProductFragment on ProductInterface {
        ...ListingProductFragment

        # Mask specific fields
        mask_type
        headgear_included
        replaceable_cushion
        cushion_type
        includes_multiple_cushion_sizes
        mask_good_for
        sleep_position
        breathing_pref
        open_line_of_sight

        # Machine specific fields
        categories {
            ...ProductCategoriesFragment
        }
        machine_warranty
        sound_level
        machine_weight
        machine_length
        machine_width
        machine_height
        humidification_available
        humidification_type
        heated_hose_option
        lowest_pressure
        highest_pressure
        exhalation_relief
        ramp_mode
        max_altitude
        battery_option
        dc_capable
        advanced_data
        auto_altitude_adjustment
        auto_start
        auto_stop
        machine_good_for
    }
    ${ListingProductFragment}
    ${ProductCategoriesFragment}
`;

export const CarouselProductFragment = gql`
    fragment CarouselProductFragment on ProductInterface {
        uid # Required for the Apollo cache unique fragment identifier
        id # Required for the Apollo cache unique fragment identifier
        sku
        name
        url_key
        review_count
        displayable_in_stock
        displayable_allow_backorders
        # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
        product_availability
        product_availability_label
        # The 'manufacturer' key must always be included for 'brand' to return proper data
        manufacturer
        brand {
            id # Required for the Apollo cache unique fragment identifier
            label
        }
        advanced_pricing {
            msrp
            msrp_display_actual_price_type
        }
        price_tiers {
            quantity
        }
        price_range {
            minimum_price {
                final_price {
                    value
                }
                regular_price {
                    value
                }
                discount {
                    amount_off
                    percent_off
                }
                promotion_discount @include(if: $hasPromoCode) {
                    applies_to_product
                    amount_off_regular_price
                    percent_off_regular_price
                    price {
                        value
                    }
                }
            }
        }
        ... on ConfigurableProduct {
            variants {
                attributes {
                    ...ConfigurableAttributeOptionFragment
                }
                product {
                    uid # Required for the Apollo cache unique fragment identifier
                    id # Required for the Apollo cache unique fragment identifier
                    sku
                    displayable_in_stock
                    displayable_allow_backorders
                    # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                    product_availability
                    product_availability_label
                }
            }
        }
    }
    ${ConfigurableAttributeOptionFragment}
`;
