import { gql } from 'graphql-tag';

export const SubscriptionIntervalFragment = gql`
    fragment SubscriptionIntervalFragment on SubscriptionInterval {
        uid # Required for the Apollo cache unique fragment identifier
        id
        option_id # Required for UID
        option_type_id # Required for UID
        frequency_count
        frequency_unit
        installment_price
    }
`;
