import { getComparisonsState } from '@src/graphql/operations/productComparisonsState';

export const mutations = {
    /**
     * Mutate the `comparisons` state with adding product.
     *
     * @param  {Object}  parent              The parent resolver instance.
     * @param  {Boolean} options.product The expanded state mutation value.
     * @param  {Object}  options.cache      The Apollo Client cache instance.
     */
    addComparisonProductSku: (parent, { productSku }, { cache }) => {
        const data = cache.readQuery({
            query: getComparisonsState,
        });

        data.comparisons.productSkus = [productSku, ...data.comparisons.productSkus];

        cache.writeData({
            data,
        });
    },

    removeComparisonProductSku: (parent, { productSku }, { cache }) => {
        const data = cache.readQuery({
            query: getComparisonsState,
        });

        data.comparisons.productSkus = data.comparisons.productSkus.filter(
            sku => sku !== productSku,
        );

        cache.writeData({
            data,
        });
    },

    setComparisonProductSkus: (parent, { productSkus }, { cache }) => {
        const data = cache.readQuery({
            query: getComparisonsState,
        });

        data.comparisons.productSkus = productSkus;

        cache.writeData({
            data,
        });
    },
};

export const initialCacheState = {
    comparisons: {
        productSkus: [],
        __typename: 'Comparisons',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
//////////////////////////
//////////////////////////////////
/////////
//////
//

//////////////////////////////////////////////////////////////

////////////////////////////////////
/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//
//////////
