// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n._20vPP {\n    transform: scale(.875) !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_20vPP"
};
module.exports = ___CSS_LOADER_EXPORT___;
