import {
    getOrderConfirmationDetails,
    getAccountCreationStatus,
} from '@graphql/operations/orderConfirmation';

export const mutations = {
    /**
     * Set's the order confirmation state
     *
     * @param  {Object}  response            The mutation response (not applicable).
     * @param  {Boolean} options             The variable options for order cofirmation details.
     * @param  {Object}  options.cache       The Apollo Client cache instance.
     */
    setOrderConfirmationState: (
        response,
        {
            email,
            address,
            items,
            orderNumber,
            orderDate,
            priceSummary,
            shippingMethod,
            subscribed,
            defaultAddressParameters,
            paymentMethod,
        },
        { cache },
    ) => {
        const data = cache.readQuery({
            query: getOrderConfirmationDetails,
        });

        // Update the data
        if (typeof email !== 'undefined') {
            data.orderConfirmation.email = email;
        }

        if (typeof address !== 'undefined') {
            data.orderConfirmation.address = address;
        }

        if (typeof items !== 'undefined') {
            data.orderConfirmation.items = items;
        }

        if (typeof orderNumber !== 'undefined') {
            data.orderConfirmation.orderNumber = orderNumber;
        }

        if (typeof orderDate !== 'undefined') {
            data.orderConfirmation.orderDate = orderDate;
        }

        if (typeof priceSummary !== 'undefined') {
            data.orderConfirmation.priceSummary = priceSummary;
        }

        if (typeof shippingMethod !== 'undefined') {
            data.orderConfirmation.shippingMethod = shippingMethod;
        }

        if (typeof subscribed !== 'undefined') {
            data.orderConfirmation.subscribed = subscribed;
        }

        if (typeof defaultAddressParameters !== 'undefined') {
            data.orderConfirmation.defaultAddressParameters = defaultAddressParameters;
        }

        if (typeof paymentMethod !== 'undefined') {
            data.orderConfirmation.paymentMethod = {
                __typename: 'ConfirmationPaymentMethod',
                ...paymentMethod,
            };
        }

        // Write the updated data to the cache
        cache.writeQuery({
            query: getOrderConfirmationDetails,
            data,
        });
    },

    /**
     * Set's the account creation status
     *
     * @param  {Object}  response                   The mutation response (not applicable).
     * @param  {Boolean} options.wasAccountCreated  The mutation variables (not applicable).
     * @param  {Object}  options.cache              The Apollo Client cache instance.
     */
    setAccountCreationStatus: (response, { wasAccountCreated }, { cache }) => {
        const data = cache.readQuery({
            query: getAccountCreationStatus,
        });

        // Update the flag
        data.wasAccountCreated.status = wasAccountCreated;

        cache.writeData({ data });
    },
};

export const initialCacheState = {
    orderConfirmation: {
        email: null,
        address: null,
        items: [],
        orderDate: null,
        orderNumber: null,
        priceSummary: null,
        shippingMethod: null,
        subscribed: null,
        defaultAddressParameters: null,
        paymentMethod: null,
        __typename: 'OrderConfirmation',
    },
    wasAccountCreated: {
        status: false,
        __typename: 'AccountCreationStatus',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
////////////////////////////////////////
/////////////////////////
//////////////////////////
////////////////////////
//////////////////////////////////
/////////

//////////////////////////////////
////////////////////////////////
///////////////////////////////
/////////

////////////////////////////////
//////////////////////////
//////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////
////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////////////
////////////////////////////////
//////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
/////////

////////////////////////////////////
////////////////////////////
/////////
//////
//

/////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////////
//

////////////////////////////////////
///////////////////////////////
//////////////////////
//////////////////////////////////////
///////////////////////////////////
//////////////////////////
/////////////////////////////
/////////////////////////////////
///////////////////////////////////////////////
////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//
//////////
