<template>
    <div v-bind:class="[$style.container, is404 && $style.container404]">
        <AppLoadingIcon v-if="isInternalHardRedirect" />
        <ErrorPage404 v-else-if="is404" />
        <ErrorPage500 v-else />
    </div>
</template>

<script>
    import { getRoutingMetaData } from '@graphql/operations/routing';
    import { internalHardRedirects } from '@constants/internalHardRedirects';

    export default {
        /**
         * The Vue Apollo special option for queries in Vue components.
         *
         * @link https://apollo.vuejs.org/guide/apollo/
         */
        apollo: {
            routingMetaData: {
                query: getRoutingMetaData,
            },
        },

        /**
         * Declaratively register required components.
         *
         * @link https://vuejs.org/v2/guide/components.html#Local-Registration
         */
        components: {
            AppLoadingIcon: () => import('@components/Framework/AppLoadingIcon'),
            ErrorPage404: () => import('@components/ErrorPage404'),
            ErrorPage500: () => import('@components/ErrorPage500'),
        },

        /**
         * Define the properties passed into the component from the parent.
         *
         * @link https://vuejs.org/v2/guide/components.html#Props
         */
        props: {
            error: {
                type: Object,
                required: true,
            },
        },

        /**
         * Cached computed methods for complex logic on the view layer.
         *
         * @link https://vuejs.org/v2/guide/computed.html
         *
         * @type Object The computed functions
         */
        computed: {
            isInternalHardRedirect() {
                return (
                    !this.routingMetaData.isInitialPageLoad &&
                    internalHardRedirects.some(regex => (this.$route?.path || '').match(regex))
                );
            },
            is404() {
                return this.error?.statusCode === 404;
            },
        },

        /**
         * Custom page meta data property for the page components.
         *
         * @type {Object}
         */
        head() {
            // Avoid using the `getPageMetaData` utility function to prevent that code
            // from being added to the app entry JS file just for this error layout
            return {
                title: this.isInternalHardRedirect ? '' : this.is404 ? '404' : '500',
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: this.isInternalHardRedirect
                            ? ''
                            : this.is404
                            ? '404 - Page Not Found'
                            : '500 - Server Error',
                    },
                ],
            };
        },
    };
</script>

<style module>
    .container {
        @apply py-8;
    }

    .container404 {
        @apply py-0;
    }
</style>
