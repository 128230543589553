import { gql } from 'graphql-tag';

export const getCartNotificationState = gql`
    query getCartNotificationState {
        cartNotification @client {
            isFlyoutActive
            isBannerActive
            isRemovalAction
            isShowingMiniCart
        }
    }
`;

export const setCartNotificationState = gql`
    mutation setCartNotificationState(
        $isFlyoutActive: Boolean
        $isBannerActive: Boolean
        $isRemovalAction: Boolean
        $isShowingMiniCart: Boolean
    ) {
        setCartNotificationState(
            isFlyoutActive: $isFlyoutActive
            isBannerActive: $isBannerActive
            isRemovalAction: $isRemovalAction
            isShowingMiniCart: $isShowingMiniCart
        ) @client
    }
`;
