/**
 * Inject the static rendering state into the Vue instance.
 */
export default ({ req }, inject) => {
    // This header is set at Fastly when a bot is detected in the UA string matching regex found here:
    // https://github.com/hgoebl/mobile-detect.js/blob/2e1697b506cd13bc36906f8437f7a8327010f373/mobile-detect.js#L292
    const isStaticRender = process.server ? (req?.headers || {})['x-ua-entity'] === 'bot' : false;

    inject('isStaticRender', isStaticRender);
};
