import { gql } from 'graphql-tag';

export const getComparisonsState = gql`
    query getComparisonsState {
        comparisons @client {
            productSkus
        }
    }
`;

export const setComparisonsState = gql`
    mutation setComparisonProductSkus($productSkus: [String]) {
        setComparisonProductSkus(productSkus: $productSkus) @client
    }
`;

export const addComparisonsState = gql`
    mutation addComparisonProductSku($productSku: String!) {
        addComparisonProductSku(productSku: $productSku) @client
    }
`;

export const removeComparisonsState = gql`
    mutation removeComparisonProductSku($productSku: String!) {
        removeComparisonProductSku(productSku: $productSku) @client
    }
`;
