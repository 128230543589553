import { onError } from '@apollo/client/link/error';
import { redirectWithQueryString } from '@utilities/redirectWithQueryString';
import { logError } from '@utilities/logError';
import { genericCartItemRemovalErrorMessage } from '@utilities/cart/actions/utilities/getPublicMessageErrorInstance';

/**
 * Extend the native Error class for GraphQL errors
 * to handle sending proper data to Sentry.io.
 */
export class GraphQLError extends Error {
    /**
     * GraphQLError constructor.
     *
     * @param  {Object} graphqlError GraphQL error object instance.
     */
    constructor(graphqlError) {
        const message = graphqlError.message;

        super(message);

        this.name = this.constructor.name;

        const stacktrace = graphqlError.extensions?.exception?.stacktrace || [];

        if (stacktrace.length) {
            this.stack = stacktrace.join('\n');
        } else if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
    }
}

/**
 * Create an Apollo link instance for error handling Apollo driven GraphQL responses.
 *
 * @param  {function} options.app      The app context instance.
 * @param  {function} options.redirect The router redirect function.
 *
 * @return {ApolloLink}
 */
export const createErrorHandlerLink = ({ app, redirect, route }) => {
    /**
     * Set a redirect cookie to be used after a successful login that will
     * return the user to the route where the authorization failure was thrown.
     *
     * @param  {Object} error The GraphQL error instance.
     */
    const setAuthRedirectCookie = async error => {
        // Define the Apollo client from the application context
        const apollo = app.apolloProvider.defaultClient;

        const [
            { getRoutingMetaData },
            { setCookie },
            { CPAP_STORAGE_KEY_PREFIX, AUTH_REDIRECT_KEY },
        ] = await Promise.all([
            import('@graphql/operations/routing'),
            import('@utilities/cookies'),
            import('@constants/localStorageKeys'),
        ]);

        // Get the previously resolved paths
        const {
            data: {
                routingMetaData: { resolvedPaths },
            },
        } = await apollo.query({
            query: getRoutingMetaData,
        });

        const path = resolvedPaths.find(path => {
            // Sanity check to make sure we don't end up in an endless loop
            if (path.startsWith('/login') || path.startsWith('/logout')) {
                return false;
            }

            // Due to optimistic responses we route to the next page on any add to
            // cart action before the error is caught. This conditional will roll back
            // to the path of the actual page where the product was added to the cart.
            if (
                error?.path &&
                (error.path[0] || '').match(/^add\w*ProductsToCart$/) &&
                (path.startsWith('/checkout/shopping-cart') || path.startsWith('/customize'))
            ) {
                return false;
            }

            return true;
        });

        // Verify that we have a defined path string
        if (path) {
            // Set the path to return to after successful authentication
            setCookie(app.$cookies, CPAP_STORAGE_KEY_PREFIX + AUTH_REDIRECT_KEY, path);
        }
    };

    /**
     * Remove the out of stock items from the user cart.
     *
     * @param {array} items The shopping cart items from the response.
     */
    const removeOutOfStockItemsFromCart = async items => {
        const { isCartItemInStock } = await import('@utilities/cart/item/isCartItemInStock');

        // Add the out of stock products
        const outOfStockCartItems = items.filter(item => (item ? !isCartItemInStock(item) : false));

        if (outOfStockCartItems?.length) {
            const apollo = app.apolloProvider.defaultClient;
            const { removeUnavailableServerItemsFromCart } = await import(
                '@utilities/cart/actions/utilities/removeUnavailableServerItemsFromCart'
            );

            await removeUnavailableServerItemsFromCart(apollo, outOfStockCartItems, {
                message: genericCartItemRemovalErrorMessage,
            });
        }
    };

    /**
     * Remove cart items.
     *
     * @param  {object} error The GraphQL error instance.
     *
     * @return {boolean}
     */
    const removeCartItems = async (items, message) => {
        // Define the Apollo client from the application context
        const apollo = app.apolloProvider.defaultClient;
        const { removeUnavailableServerItemsFromCart } = await import(
            '@utilities/cart/actions/utilities/removeUnavailableServerItemsFromCart'
        );

        removeUnavailableServerItemsFromCart(apollo, items, {
            ...(message ? { message } : {}),
        });
    };

    /**
     * Determine if the server error is due to a missing customizable item.
     *
     * @param  {object} error The GraphQL error instance.
     *
     * @return {boolean}
     */
    const getHasRecoverableCartError = error => {
        const errorPath = error?.path || [];

        const isCartMutation = /^(add\w*ProductsTo|removeItemFrom)Cart$/.test(errorPath[0] || '');
        const baseIndex = isCartMutation ? 1 : 0;

        const isRecoverable =
            errorPath[baseIndex] === 'cart' &&
            errorPath[baseIndex + 1] === 'items' &&
            typeof errorPath[baseIndex + 2] === 'number';

        if (isRecoverable) {
            logError(error?.extensions?.exception || new Error(error.message));
        }

        return isRecoverable;
    };

    return onError(({ operation, response, graphQLErrors, networkError }) => {
        // Some errors will have graphQLErrors and networkError, because of this we need to use the else if to avoid duplicated logs
        if (graphQLErrors) {
            // Error types that should not be logged to Sentry
            const silencedErrorCategories = ['graphql-authentication', 'graphql-input'];

            // Ignored errors will be removed from the response errors array
            // before being sent to the originating query handler. This will
            // allow the successful response data to be executed.
            // Ignored errors should always be completely recoverable.
            // See https://www.apollographql.com/docs/react/data/error-handling/#ignoring-errors
            //
            // This strategy should be removed when upgrading to Vue Apollo V4 in
            // favor of using error policies on the originating query handler.
            // See https://v4.apollo.vuejs.org/guide-composable/error-handling.html
            const ignoredErrors = [
                {
                    category: 'graphql-input',
                    message: 'Some of the products are out of stock.',
                },
                {
                    category: 'graphql-input',
                    message: 'There are no source items with the in stock status',
                },
                {
                    category: 'graphql-input',
                    message: 'Some of the selected item options are not currently available.',
                },
            ];

            const hasGenericCartQueryItemsInResponse = response?.data?.cart?.items?.length;
            const throwableErrors = [];
            const removableItems = [];

            // Iterate through all GraphQL specific errors
            graphQLErrors.forEach(error => {
                // Do not log missing persisted query errors as these will be
                // handled and resent as full requests by the Apollo client
                if (error?.extensions?.code === 'PERSISTED_QUERY_NOT_FOUND') {
                    return throwableErrors.push(error);
                }

                // Get any ignored error matching this non-network GraphQL error
                const hasIgnoredError = ignoredErrors.some(
                    ({ category, message }) =>
                        error?.extensions?.category === category && error?.message === message,
                );
                const isCartError = (error?.message || '').includes('cart');

                // Determine if this is an authorization error
                if (error?.extensions?.category === 'graphql-authorization') {
                    const errorPaths = error?.path || [];

                    // Silenced conditions for `graphql-authorization` errors
                    if (
                        // Don't logout on a cart authorization error. A new cart will be created.
                        errorPaths.includes('cart') ||
                        // Ignore token revocation authorization errors as it means the token has already been invalidated
                        errorPaths.includes('revokeCustomerToken')
                    ) {
                        return throwableErrors.push(error);
                    }

                    // Set a redirect cookie to be used after a successful login that will
                    // return the user to the route where the authorization failure was thrown
                    setAuthRedirectCookie(error);

                    // Redirect to logout to revoke the user's token and forward the user to the login page
                    redirectWithQueryString(redirect, '/logout', route, app.$cookies);
                } else if (
                    !silencedErrorCategories.includes(error?.extensions?.category) ||
                    (isCartError && !hasIgnoredError)
                ) {
                    // Sentry will console the simplified native Error instance. This console
                    // log will provide greater error details in development environments
//////////////////////////////////
///////////////////////////////////////////////////////////////////////////
//////////////////////////////

                    // The log error must be passed a native Error instance for Sentry.io
                    logError(new GraphQLError(error), {
                        ...error,
                        stacktrace: (error.extensions?.exception?.stacktrace || []).join('\n'),
                    });
                }

                const hasRecoverableCartError = getHasRecoverableCartError(error);

                // Keep only non-ignored errors
                if (!hasIgnoredError && !hasRecoverableCartError) {
                    throwableErrors.push(error);
                }

                // Determine if this is a generic cart query response, and not a cart mutation response
                if (hasGenericCartQueryItemsInResponse) {
                    if (hasRecoverableCartError) {
                        const itemIndex = Number(error.path[2]);
                        const removableItem = response.data.cart.items[itemIndex];

                        if (removableItem) {
                            removableItems.push(removableItem);
                        }
                    }
                } else if (hasRecoverableCartError) {
                    // This `else-if` will to handle cart mutation responses
                    const itemIndex = Number(error.path[3]);
                    const removableItem = (response?.data?.[error.path[0]]?.cart?.items || [])[
                        itemIndex
                    ];

                    if (removableItem) {
                        removableItems.push(removableItem);
                    }
                }
            });

            if (hasGenericCartQueryItemsInResponse) {
                // Remove any null items from the customer shopping cart for the client
                response.data.cart.items = response.data.cart.items.filter(item => item);

                if (process.client) {
                    // Remove any out of stock items from the customer shopping cart
                    removeOutOfStockItemsFromCart(response.data.cart.items);

                    // Remove non-out-of-stock items flagged for removal
                    if (removableItems?.length) {
                        removeCartItems(removableItems, genericCartItemRemovalErrorMessage);
                    }
                }
            } else if (removableItems.length && process.client) {
                removeCartItems(removableItems);
            }

            // Determine if the response errors have all been removed due to being ignored
            // Null the response errors to allow for response data
            // handling in the originating query handler
            if (response) {
                response.errors = throwableErrors.length ? throwableErrors : null;
            }
        } else if (networkError) {
            if (networkError.response?.status === 401) {
                setAuthRedirectCookie(networkError);

                // Redirect to logout to revoke the user's token and forward the user to the login page
                redirectWithQueryString(redirect, '/logout', route, app.$cookies);
            }

            logError(networkError);
        }
    });
};
