import { setComparisonsState } from '@src/graphql/operations/productComparisonsState';

/**
 * Reset any stored comparisons on route changes.
 *
 * @param  {object} options.store The Vuex store instance.
 */
export default ({ app }) => {
    if (process.client) {
        const apollo = app.apolloProvider.defaultClient;

        apollo.mutate({
            mutation: setComparisonsState,
            variables: {
                productSkus: [],
            },
        });
    }
};
