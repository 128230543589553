import { setFlashMessageState } from '@graphql/operations/flashMessage';

/**
 * Helper utility to display a flash message without blocking execution.
 *
 * @param  {object} $apollo          The vue-apollo instance.
 * @param  {string} message          The message for the user.
 * @param  {string} options.type     The flash message type.
 * @param  {number} options.timeout  The timeout before automatically hiding the flash message.
 */
export const flashMessage = async ($apollo, message, {
    type = 'error',
    timeout = 5000,
} = {}) => {
    await $apollo.mutate({
        mutation: setFlashMessageState,
        variables: {
            message,
            type,
            timeout,
        },
    });

    if (process.client) {
        window.dataLayer.push({
            event: 'flash_message',
            eventName: 'alert',
            eventNonInteractionHit: 1,
            interaction_type: 'impression',
            is_user_interaction: 'false',
            severity: type,
            message_text: message,
        });
    }
};
