import { ApolloLink } from '@apollo/client/link/core';
import { createUploadLink } from 'apollo-upload-client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { createMutationQueueLink } from '@config/apollo/link/mutationQueue';
import { createErrorHandlerLink } from '@config/apollo/link/errorHandler';
import { createCartItemsLink } from '@config/apollo/link/cartItems';
import { createCmsPreviewHeaderLink } from '@config/apollo/link/cmsPreviewHeader';
import { sha256 } from 'crypto-hash';
import { createCache } from '@config/apollo/cache';
import { typeDefs, initialCacheState, Query, Mutation } from '@config/apollo/graphql';

// Create the Apollo HTTP link for the client
const getHttpLink = ({ $config }) =>
    // This is a drop-in replacement for the native Apollo Client `HttpLink`` but it allows for File type uploads
    // See https://github.com/jaydenseric/apollo-upload-client
    createUploadLink({
        uri: $config.graphqlHttpEndpoint,
        persisting: true,
    });

// We are using the up-to-date persisted query methods that's now
// provided out-of-the-box with the Apollo Client library.
// See https://www.apollographql.com/docs/react/api/link/persisted-queries/
// and See https://www.apollographql.com/docs/apollo-server/performance/apq/
const persistedQueriesLink = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
});

/**
 * Create a new Apollo client instance.
 *
 * @param  {Object} context The Nuxt context instance.
 *
 * @return {Object}
 */
export default context => ({
    // Disable the default HTTP link so that we can use the
    // modern HTTP link and modern persisted query link
    defaultHttpLink: false,
    link: ApolloLink.from([
        createMutationQueueLink(),
        persistedQueriesLink,
        createErrorHandlerLink(context),
        createCmsPreviewHeaderLink(context),
        createCartItemsLink(context),
        // The HTTP link must come last
        getHttpLink(context),
    ]),
    // Disable the default persisting flag because we are overriding with the modern,
    // non-deprecated persisted query link now provided with the Apollo Client library
    // See https://www.apollographql.com/docs/react/api/link/persisted-queries/
    persisting: false,
    cache: createCache(),
///////////////////////////////////
/////////////
//////////////
    onCacheInit: cache => {
        // Do not reset initial cache state on client as Vue
        // Apollo will restore the cache from the Nuxt context
        // See https://apollo.vuejs.org/guide/ssr.html#create-apollo-client
        if (process.client && window.__NUXT__?.apollo?.defaultClient) {
            cache.restore(window.__NUXT__.apollo.defaultClient);
        } else {
            cache.writeData({ data: initialCacheState });
        }
    },
    resolvers: {
        Query,
        Mutation,
    },
});
