/**
 * Scroll to the top of the page on browser route transitions.
 *
 * @param  {object} options.app The app context object.
 */
export default ({ app }) => {
    const { router } = app;

    let isProgrammaticRouting = false;

    // Iterate through each programmatic router action name
    ['push', 'replace', 'go', 'back', 'forward'].forEach(action => {
        // Update the action's event handler to set the programmatic
        // routing flag on each programmatic routing event
        const method = router[action];

        router[action] = (...args) => {
            isProgrammaticRouting = true;

            method.apply(router, args);
        };
    });

    // Before handler on each route update
    router.beforeEach((to, from, next) => {
        // The `from.name` is `null` for initial load or page reload
        if (from.name !== null && !isProgrammaticRouting) {
            setTimeout(async () => {
                const { scrollTo } = await import('@utilities/scrollTo');

                scrollTo(document.body, 'auto');
            }, 500);
        }

        // Clear flag
        isProgrammaticRouting = false;

        next();
    });
};
