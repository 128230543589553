import { ApolloLink } from '@apollo/client/link/core';
import { Observable } from '@apollo/client/core';
import { isExpectedOperationType } from '@utilities/apollo/isExpectedOperationType';
import { isCartItemReferenceOnly } from '@utilities/cart/item/isCartItemReferenceOnly';
import { isCartItemCustomizableOptionTypeValid } from '@utilities/cart/item/isCartItemCustomizableOptionTypeValid';

/**
 * Create an Apollo link instance for error handling Apollo driven GraphQL responses.
 *
 * @param  {function} options.app  The app context instance.
 *
 * @return {ApolloLink}
 */
export const createCartItemsLink = ({ app }) => {
    return new ApolloLink((operation, forward) => {
        return new Observable(observer => {
            return forward(operation).subscribe({
                next: response => {
                    // Only instantiate Apollo link observer if this is a mutation
                    if (isExpectedOperationType(operation, 'mutation')) {
                        const isCartMutation = Boolean(
                            response?.data?.removeItemFromCart ||
                                response?.data?.addProductsToCart ||
                                response?.data?.updateCartItems,
                        );
                        if (process.client && isCartMutation) {
                            (async () => {
                                const { sendRefreshSessionMessage } = await import(
                                    '@utilities/sendRefreshSessionMessage'
                                );
                                sendRefreshSessionMessage('cart-update');
                            })();
                        }
                    }
                    // Only instantiate Apollo link observer if this is a query
                    else if (isExpectedOperationType(operation, 'query')) {
                        const removableItems = [];
                        const hasCartItemsInResponse = response?.data?.cart?.items?.length;

                        if (hasCartItemsInResponse) {
                            // Add any reference only products to the removal items collection
                            response.data.cart.items
                                // Filter out any null items from the customer shopping cart
                                .filter(item => item)
                                .forEach(item => {
                                    if (
                                        isCartItemReferenceOnly(item) ||
                                        !isCartItemCustomizableOptionTypeValid(item)
                                    ) {
                                        removableItems.push(item);
                                    }
                                });

                            // Asynchronously remove items flagged for removal
                            if (process.client && removableItems?.length) {
                                (async () => {
                                    // Define the Apollo client from the application context
                                    const apollo = app.apolloProvider.defaultClient;
                                    const [
                                        { removeUnavailableServerItemsFromCart },
                                        { genericCartItemRemovalErrorMessage },
                                    ] = await Promise.all([
                                        import(
                                            '@utilities/cart/actions/utilities/removeUnavailableServerItemsFromCart'
                                        ),
                                        import(
                                            '@utilities/cart/actions/utilities/getPublicMessageErrorInstance'
                                        ),
                                    ]);

                                    removeUnavailableServerItemsFromCart(apollo, removableItems, {
                                        message: genericCartItemRemovalErrorMessage,
                                    });
                                })();
                            }
                        }
                    }
                    observer.next(response);
                },
                error: observer.error,
                complete: observer.complete.bind(observer),
            });
        });
    });
};
