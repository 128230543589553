import { getCartNotificationState } from '@graphql/operations/cartNotification';

export const mutations = {
    /**
     * Set the client-only cart notification active state.
     *
     * @param  {Object}  response               The mutation response (not applicable).
     * @param  {Boolean} options.isBannerActive The cart ID mutation value.
     * @param  {Object}  options.cache          The Apollo Client cache instance.
     */
    setCartNotificationState: (
        response,
        { isFlyoutActive, isBannerActive, isRemovalAction, isShowingMiniCart },
        { cache },
    ) => {
        const data = cache.readQuery({
            query: getCartNotificationState,
        });

        if (typeof isFlyoutActive === 'boolean') {
            data.cartNotification.isFlyoutActive = isFlyoutActive;
        }

        if (typeof isBannerActive === 'boolean') {
            data.cartNotification.isBannerActive = isBannerActive;
            data.cartNotification.isRemovalAction = Boolean(isRemovalAction);
        }

        if (typeof isShowingMiniCart === 'boolean') {
            data.cartNotification.isShowingMiniCart = isShowingMiniCart;
        }

        cache.writeData({ data });
    },
};

export const initialCacheState = {
    cartNotification: {
        isFlyoutActive: false,
        isBannerActive: false,
        isRemovalAction: false,
        isShowingMiniCart: true,
        __typename: 'CartNotification',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
///////////////////////////////
////////////////////////////////////
///////////////////////////////////
////////////////////////////////////
//////////////////////////////////////
/////////
//////
//

////////////////////////////////////////////////////////////////////////

////////////////////////////////////
//////////////////////////////
////////////////////////////////
////////////////////////////////
/////////////////////////////////
//////////////////////////////////
/////////////////////////
//
//////////
