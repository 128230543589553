/**
 * Set cookie helper function
 *
 * @param  {Object} cookies The `cookie-universal` instance.
 * @param  {String} key     The cookie key.
 * @param  {String} value   The cookie value.
 * @param  {Object} options The optional cookie options.
 */
export const setCookie = (cookies, key, value, options = {}) => {
    cookies.set(key, value, {
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'lax',
        // Make this a session cookie by default
        ...(!options.expires && !options.maxAge ? {
            expires: '',
        } : {}),
        // Passed options will override defaults with the same object key
        ...options,
    });
};

/**
 * Remove cookie helper function
 *
 * @param  {Object} cookies The `cookie-universal` instance.
 * @param  {String} key     The cookie key.
 * @param  {Object} options The optional cookie options.
 */
export const removeCookie = (cookies, key, options = {}) => {
    cookies.remove(key, {
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        // Passed options will override defaults with the same object key
        ...options,
    });
};
