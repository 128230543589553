import { gql } from 'graphql-tag';

export const getCartState = gql`
    query getCartState {
        clientCart @client {
            cachedId
            isMutating
            isOrdering
            promoCode
            shippingAddress {
                city
                country {
                    code
                    label
                }
                firstname
                lastname
                postcode
                region {
                    code
                    label
                    region_id
                }
                selected_shipping_method {
                    carrier_code
                    carrier_title
                    method_code
                }
                street
                telephone
            }
        }
    }
`;

export const setCachedCartId = gql`
    mutation setCachedCartId($id: String!) {
        setCachedCartId(id: $id) @client
    }
`;

export const setCartState = gql`
    mutation setCartState($isOrdering: Boolean, $isMutating: Boolean) {
        setCartState(isOrdering: $isOrdering, isMutating: $isMutating) @client
    }
`;

export const setPromoCode = gql`
    mutation setPromoCode($code: String!) {
        setPromoCode(code: $code) @client
    }
`;

export const setShippingAddressState = gql`
    mutation setShippingAddressState($address: CartAddressInput) {
        setShippingAddressState(address: $address) @client
    }
`;
