import { gql } from 'graphql-tag';

export const SelectedConfigurableOptionFragment = gql`
    fragment SelectedConfigurableOptionFragment on SelectedConfigurableOption {
        configurable_product_option_uid # Required for the unique Apollo cache identifier
        configurable_product_option_value_uid # Required for the unique Apollo cache identifier
        option_label
        value_label
        sku
    }
`;
