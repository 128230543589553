/**
 * Update the GA site speed sample rate on first client-side
 * route change to prevent duplicate data in GA reporting.
 *
 * See: https://www.simoahava.com/analytics/site-speed-sample-rate-multiple-page-views/
 *
 * @param  {Object} options.store The Vuex store instance.
 * @param  {Object} options.$gtm  The Nuxt GTM module instance.
 */
export default async ({ app, $gtm }) => {
    if (process.client) {
        // get the local state data
        const apollo = app.apolloProvider.defaultClient;

        const { getRoutingMetaData } = await import('@graphql/operations/routing');

        const { data } = await apollo.query({
            query: getRoutingMetaData,
        });

        // Only trigger on client-side router view changes
        if (!data?.routingMetaData?.isInitialPageLoad) {
            // Determine if the value has already been sent to GA to keep the data layer bandwidth minimal
            // FIXME: Ask Jeff C if this solution is proper
            if (
                !(window.dataLayer || []).some(
                    ({ siteSpeedSampleRate }) => typeof siteSpeedSampleRate !== 'undefined',
                )
            ) {
                // Set the siteSpeedSampleRate to 0 to prevent duplicate data in reporting
                // See: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#siteSpeedSampleRate
                $gtm.push({
                    siteSpeedSampleRate: 0,
                });
            }
        }
    }
};
