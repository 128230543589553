import { getGeoLocationState } from '@graphql/operations/geoLocation';

export const mutations = {
    /**
     * Set the user's geolocation.
     *
     * @param  {Object}  parent              The parent resolver instance.
     * @param  {String}  options.country     The country code.
     * @param  {String}  options.state       The state code.
     * @param  {Object}  context.cache       The Apollo Client cache instance.
     */
    setLocation: (parent, { country, state }, { cache }) => {
        const data = cache.readQuery({
            query: getGeoLocationState,
        });

        data.location.country = country || data.location.country;
        data.location.state = state || data.location.state;

        cache.writeData({
            data,
        });
    },
};

export const initialCacheState = {
    location: {
        country: '',
        state: '',
        __typename: 'Location',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
///////////////////////
////////////////////////////
//////////////////////////
/////////
//////
//

////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////
//////////
