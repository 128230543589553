import { gql } from 'graphql-tag';

export const getHomeSleepTestState = gql`
    query getHomeSleepTestState {
        homeSleepTest @client {
            isTakingTest
        }
    }
`;

export const setHomeSleepTestState = gql`
    mutation setHomeSleepTestState($isTakingTest: Boolean!) {
        setHomeSleepTestState(isTakingTest: $isTakingTest) @client
    }
`;
