import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

import { Integrations as TracingIntegrations } from '@sentry/tracing'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F905af05ac48e4bb8857a819fce8540c3@o291093.ingest.sentry.io\u002F5416575",
    environment:"production",
    release:"fc9b1714d9c22a230f41f36efcb5951ec601bd8a",
    beforeSend:(event, { originalException, syntheticException }) => {
        if (!['production', 'staging'].includes(event.environment)) {
          // See https://github.com/getsentry/sentry-javascript/issues/1600
          setTimeout(() => {
            console.error(originalException || syntheticException); // eslint-disable-line no-console
            console.log('Sentry.io Exception Event: ', event); // eslint-disable-line no-console
          });
        }

        const errorMessage = String(originalException || syntheticException);

        if (errorMessage.includes('status code 401')) {
          return null;
        }

        return event;
      },
    tracesSampleRate:0.04
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false,"tracing":true,"tracingOptions":{"hooks":["mount","update"],"timeout":2000,"trackComponents":true}}})
  ]

    config.integrations.push(new TracingIntegrations.BrowserTracing({}))

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
