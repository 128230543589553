import { getRoutingMetaData } from '@graphql/operations/routing';

export const mutations = {
    /**
     * Mutate the `resolutionCount` state with an incremented number.
     *
     * @param  {Object}  parent          The parent resolver instance.
     * @param  {Object}  variables       The mutation variables.
     * @param  {Object}  options.cache   The Apollo Client cache instance.
     */
    logResolvedRoute: (parent, { path }, { cache }) => {
        const data = cache.readQuery({
            query: getRoutingMetaData,
        });

        data.routingMetaData.resolutionCount = data.routingMetaData.resolutionCount + 1;
        data.routingMetaData.isInitialPageLoad = data.routingMetaData.resolutionCount < 2;
        // We must use a shallow copy instead of a `push()` to avoid an Apollo cache update bug
        // See https://github.com/apollographql/apollo-client/issues/4498
        data.routingMetaData.resolvedPaths = [path, ...data.routingMetaData.resolvedPaths];

        cache.writeData({ data });
    },
};

export const initialCacheState = {
    routingMetaData: {
        resolutionCount: 0,
        isInitialPageLoad: true,
        resolvedPaths: [],
        __typename: 'RoutingMetaData',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
//////////////////////////////
/////////////////////////////////////
///////////////////////////////////////
////////////////////////////////////
/////////
//////
//

//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////
//////////
