/**
 * Initialize plugin to modify GTM tag attributes.
 *
 * @param {object} options.app               The app context object.
 * @param {object} options.beforeNuxtRender  Nuxt specific method to update `__NUXT__` variable rendered on client-side.
 * @param {object} options.$config           The Nuxt public runtime configuration options.
 * @param {object} options.$gtm  The Nuxt GTM module instance.
 *
 */
export default ({ app, beforeNuxtRender, $config, $gtm, res, req }) => {
    let hasGtmPluginInitialized = false;
    let isAwaitingGtmVariables = false;

    const asyncLoadOneTrustIfNotLoaded = () => {
        setTimeout(async () => {
            const { awaitVariable } = await import('@utilities/awaitVariable');

            if (!window.OneTrust?.ToggleInfoDisplay || !window.google_tag_manager) {
                // Let's wait for the `OnetrustActiveGroups` to be set so we know we have valid consent groups
                // before initializing GTM if there is no consent cookie (this is their first visit or cookie is blocked).
                await Promise.all([
                    awaitVariable('OneTrust.ToggleInfoDisplay', window, 5000, 50, null),
                    awaitVariable('google_tag_manager', window, 5000, 50),
                ]);

                window.oneTrustConsentSession = window.oneTrustConsentSession || {};

                if (!window.OneTrust?.ToggleInfoDisplay) {
                    // Set the session `isBlockingReload` variable to true to avoid a reload when setting consent for opt-in;
                    window.oneTrustConsentSession.isBlockingReload = true;

                    $gtm.push({ event: 'loadOneTrustConsentJs' });

                    window.dataLayer.push({ event: 'otToggleDnsButtonVisibility' });

                    window.setTimeout(() => {
                        // Let's re-enable reload capabilities after the consent is set.
                        window.oneTrustConsentSession.isBlockingReload = false;
                    }, 5000);
                }
            }
        }, 500);
    };

    if (process.server) {
        // Before we add tag script for GTM, let's make sure to add attributes needed.
        // There is no way to do this through the native GTM Nuxt plugin.
        beforeNuxtRender(() => {
            const gtmScript = app.head.script.find(s => s.hid === 'gtm-script');

            // Add data attribute for OneTrust to prevent OneTrust "Auto-blocking" from blocking GTM load.
            gtmScript['data-ot-ignore'] = 'optanon-category-C0001';
        });
    }

    if (process.client) {
        if (app.$cookies?.get('usx_geo_country_code') && app.$cookies?.get('usx_geo_region_code')) {
            window.OneTrust = window.OneTrust || {};

            window.OneTrust.geolocationResponse = {
                stateCode: String(app.$cookies?.get('usx_geo_region_code') || ''),
                countryCode: String(app.$cookies?.get('usx_geo_country_code') || ''),
            };
        }

        window.dataLayer = window?.dataLayer || [];

        // Check for Cookie before OneTrust loads.
        const hasOneTrustConsentCookie = app.$cookies?.get('OptanonConsent');
        const hasGtmContainerInitialized = window.dataLayer.some(entry => entry['gtm.start']);
        const isOneTrustLoadedInHead =
            app.$cookies?.get('usx_dns_opt_out') &&
            Number(app.$cookies?.get('usx_dns_opt_out')) === 1;

        if (!hasGtmContainerInitialized && !hasGtmPluginInitialized && !isAwaitingGtmVariables) {
            isAwaitingGtmVariables = true;

            (async () => {
                const hasAuthToken = app.$apolloHelpers.getToken();

                // If the user is logged in, we need to set their identifier
                // before loading GTM so we fetch the proper preferences before trying to trigger tags.
                if (hasAuthToken) {
                    try {
                        const apollo = app.apolloProvider.defaultClient;
                        const { getCustomerProfile } = await import(
                            '@graphql/operations/magento/usx/getCustomerProfile'
                        );
                        const {
                            data: { customer },
                        } = await apollo.query({ query: getCustomerProfile });
                        const { utid, one_trust_token: token } = customer;

                        if (utid && token) {
                            const subjectParameters = {
                                id: utid,
                                isAnonymous: false,
                                token,
                            };

                            window.OneTrust = window.OneTrust || {};

                            window.OneTrust.dataSubjectParams = subjectParameters;

                            if (window.OneTrust?.setDataSubjectId) {
                                window.OneTrust.setDataSubjectId(utid);
                            }
                        }
                    } catch (error) {
                        const { logError } = await import('@utilities/logError');

                        // Log the error.
                        logError(error);
                    }
                }

                // We have OneTrust Cookie, so we know categories
                // We can load GTM now since categories will be set when tag trigger conditionals are evaluated.
                if (!isOneTrustLoadedInHead || hasOneTrustConsentCookie) {
                    hasGtmPluginInitialized = true;
                    isAwaitingGtmVariables = false;

                    asyncLoadOneTrustIfNotLoaded();

                    // Manually initialize GTM if we already know the `OnetrustActiveGroups` through a cookie.
                    return $gtm.init($config.gtmContainerId);
                }

                // We don't have a OneTrust cookie, so let's await OneTrust Active Group then load GTM.

                const { awaitVariable } = await import('@utilities/awaitVariable');

                // Let's wait for the `OnetrustActiveGroups` to be set so we know we have valid consent groups
                // before initializing GTM if there is no consent cookie (this is their first visit or cookie is blocked).
                // This assumes there is no OneTrust cookie, so the OneTrust JS loaded in the head.
                await Promise.all([
                    awaitVariable(
                        'OnetrustActiveGroups',
                        window,
                        5000,
                        50,
                        null,
                        value => (value || '').split(',').filter(Boolean).length,
                    ),
                    awaitVariable('OneTrust.getGeolocationData', window, 5000, 50),
                ]);

                if (!window?.OnetrustActiveGroups || !window?.OneTrust.getGeolocationData) {
                    (async () => {
                        const Sentry = await import('@sentry/browser');

                        if (!window?.OnetrustActiveGroups) {
                            // This will be used to get an idea about how many users did not have OnetrustActiveGroups set due to timeout or JS issues.
                            Sentry.captureMessage(
                                'OnetrustActiveGroups window variable was not found for user on initial page load after 5000ms timeout',
                            );
                        }

                        if (!window?.OneTrust?.getGeolocationData) {
                            // This will be used to get an idea about how many users did not have OneTrust.getGeolocationData set due to timeout or JS issues.
                            Sentry.captureMessage(
                                'OneTrust.getGeolocationData window variable was not found for user on initial page load after 5000ms timeout',
                            );
                        }
                    })();
                }

                const getDataLayerEntryIndex = key =>
                    window.dataLayer.findIndex(entry => entry.event === key);

                const contentViewEventFirstIndex = getDataLayerEntryIndex('content-view');
                const oneTrustGroupsUpdatedEventFirstIndex = getDataLayerEntryIndex(
                    'OneTrustGroupsUpdated',
                );

                if (contentViewEventFirstIndex >= 0 && oneTrustGroupsUpdatedEventFirstIndex >= 0) {
                    // Move the content-view after the OneTrustGroupUpdated to properly reflect GroupIds after GTM is loaded.
                    window.dataLayer.splice(
                        oneTrustGroupsUpdatedEventFirstIndex,
                        0,
                        window.dataLayer.splice(contentViewEventFirstIndex, 1)[0],
                    );
                }

                // This is a manual event to set up the `OptanonWrapper` which is a native callback function
                // OneTrust uses for when preferences are updated and the preference center is interacted with.
                $gtm.push({ event: 'setUpOptanonWrapper' });

                hasGtmPluginInitialized = true;
                isAwaitingGtmVariables = false;

                // Manually initialize GTM with updated dataLayer.
                $gtm.init($config.gtmContainerId);

                asyncLoadOneTrustIfNotLoaded();
            })();
        }
    }
};
