/**
 * Determine if a cart item has a valid selected customizable option type. A cart
 * item can still be a valid customizable option type if it doesn't have any defined
 * customizable options associated with it, as this indicates a cart item that has no
 * selected customizable options, and is therefore valid without a customizable option type.
 *
 * @param  {object} cartItem The CartItemIterface data set.
 *
 * @return {boolean}
 */
export const isCartItemCustomizableOptionTypeValid = cartItem => {
    if (!cartItem) {
        return false;
    }

    // Get the unique `*_customizable_options` key based on product type
    const customizableOptionsKey = Object.keys(cartItem)
        .find(key => key.endsWith('customizable_options'));

    // Determine if the cart item has any selected customizable options,
    // if not, then this is not a customizable option item in the cart and
    // it can be assumed this is a valid cart item.
    if (!customizableOptionsKey || !cartItem[customizableOptionsKey]?.length) {
        return true;
    }

    // Return true if ALL the customizable options type values collections have a length
    // If they do not, that means this is a corrupt cart item and should be removed from the cart
    return cartItem[customizableOptionsKey]
        .every(customizableOption => customizableOption?.values?.length);
};
