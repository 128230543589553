import { getSmsSignupState } from '@graphql/operations/smsSignup';

export const mutations = {
    /**
     * Set the client-only sms signup states.
     *
     * @param  {Object}  response                       The mutation response (not applicable).
     * @param  {Boolean} options.isOptingInForSms       The flag to check if the customer is opting for SMS updates.
     * @param  {String} options.email                   Customer's email address.
     *
     * @return {Object}
     */
    setSmsSignupState: (response, { isOptingInForSms, email }, { cache }) => {
        const data = cache.readQuery({
            query: getSmsSignupState,
        });

        if (typeof isOptingInForSms !== 'undefined') {
            data.smsSignup.isOptingInForSms = isOptingInForSms;
        }

        if (typeof email !== 'undefined') {
            data.smsSignup.email = email;
        }

        cache.writeData({ data });

        return data;
    },

    /**
     * Reset the sms opt in flag.
     *
     * @param  {Object}  response      The mutation response (not applicable).
     * @param  {Object}  variables     The mutation variables if applicable.
     * @param  {Object}  options.cache The Apollo Client cache instance.
     *
     * @return {Object}
     */
    resetSmsSignupState(response, variables, { cache }) {
        const data = cache.readQuery({
            query: getSmsSignupState,
        });

        data.smsSignup.isOptingInForSms = false;
        data.smsSignup.email = null;

        cache.writeData({ data });

        return data;
    },
};

export const initialCacheState = {
    smsSignup: {
        isOptingInForSms: false,
        email: null,
        __typename: 'SmsSignup',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
////////////////////
////////////////////////
//////////////////////////////////////
/////////////////////////
/////////
//////
//

//////////////////////////////////////////////////////////

////////////////////////////////////
/////
//////////////////////////
//////////////////////////////////////
/////////////////////////
////////////////////
//////
//
//////////
