import { getSplitTests } from '@graphql/operations/splitTesting';

export const mutations = {
    /**
     * Add a new split test to the Apollo local store collection.
     *
     * @param  {Object}  response              The mutation response (not applicable).
     * @param  {Boolean} options.label         The split test identifying label.
     * @param  {Boolean} options.experimentId  The split test experiment ID from the A/B test provider.
     * @param  {Boolean} options.variationId   The split test variation ID from the A/B test provider.
     * @param  {Object}  options.cache         The Apollo Client cache instance.
     */
    addSplitTest: (response, { label, experimentId, variationId }, { cache }) => {
        const data = cache.readQuery({
            query: getSplitTests,
        });

        data.splitTests.push({
            label,
            experimentId,
            variationId,
            __typename: 'SplitTest',
        });

        cache.writeData({ data });
    },
};

export const initialCacheState = {
    splitTests: [],
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
////////////////////////
///////////////////////////
//////////////////////////////////
/////////////////////////////////
/////////
//////
//

/////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////
//////////
