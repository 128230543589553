import { gql } from 'graphql-tag';

export const getUserInterfaceState = gql`
    query getUserInterfaceState {
        userInterface @client {
            isHeaderFixedToTop
            isMobileMenuExpanded
            isMobileAccountMenuExpanded
            isMobileAccountHeaderMenuExpanded
            isSearchBarExpanded
            isContactFlyoutOpen
            isProductAddToCartStickyActive
            isCompatibilityFilterModalOpen
            isMinimalCheckoutActive
            hasMissingProductVariant
            homepageBannerMobileWidth
            homepageBannerMobileHeight
            homepageBannerTabletWidth
            homepageBannerTabletHeight
            homepageBannerDesktopWidth
            homepageBannerDesktopHeight
            hasInteracted
            searchQuery
            algoliaQueryId
            algoliaQueryTrackedProductId
            algoliaFiltersCsv
            isAlgoliaMiniCartPdpRedirect
            contactFlyoutCsrImage
            contactFlyoutCsrName
            contactFlyoutReview
            tableOfContentsHeaderItems {
                title
                href
            }
        }
    }
`;

export const setUserInterfaceState = gql`
    mutation setUserInterfaceState(
        $isHeaderFixedToTop: Boolean
        $isMobileMenuExpanded: Boolean
        $isMobileAccountMenuExpanded: Boolean
        $isMobileAccountHeaderMenuExpanded: Boolean
        $isSearchBarExpanded: Boolean
        $isContactFlyoutOpen: Boolean
        $isProductAddToCartStickyActive: Boolean
        $isCompatibilityFilterModalOpen: Boolean
        $isMinimalCheckoutActive: Boolean
        $homepageBannerMobileWidth: Number
        $homepageBannerMobileHeight: Number
        $homepageBannerTabletWidth: Number
        $homepageBannerTabletHeight: Number
        $homepageBannerDesktopWidth: Number
        $homepageBannerDesktopHeight: Number
        $hasInteracted: Boolean
        $searchQuery: String
        $algoliaQueryId: String
        $algoliaQueryTrackedProductId: String
        $algoliaFiltersCsv: String
        $isAlgoliaMiniCartPdpRedirect: String
        $contactFlyoutCsrImage: String
        $contactFlyoutCsrName: String
        $contactFlyoutReview: String
        $tableOfContentsHeaderItems: [TableOfContentsHeaderItem]!
    ) {
        setUserInterfaceState(
            isHeaderFixedToTop: $isHeaderFixedToTop
            isMobileMenuExpanded: $isMobileMenuExpanded
            isMobileAccountMenuExpanded: $isMobileAccountMenuExpanded
            isMobileAccountHeaderMenuExpanded: $isMobileAccountHeaderMenuExpanded
            isSearchBarExpanded: $isSearchBarExpanded
            isContactFlyoutOpen: $isContactFlyoutOpen
            isProductAddToCartStickyActive: $isProductAddToCartStickyActive
            isCompatibilityFilterModalOpen: $isCompatibilityFilterModalOpen
            isMinimalCheckoutActive: $isMinimalCheckoutActive
            homepageBannerMobileWidth: $homepageBannerMobileWidth
            homepageBannerMobileHeight: $homepageBannerMobileHeight
            homepageBannerTabletWidth: $homepageBannerTabletWidth
            homepageBannerTabletHeight: $homepageBannerTabletHeight
            homepageBannerDesktopWidth: $homepageBannerDesktopWidth
            homepageBannerDesktopHeight: $homepageBannerDesktopHeight
            hasInteracted: $hasInteracted
            searchQuery: $searchQuery
            algoliaQueryId: $algoliaQueryId
            algoliaQueryTrackedProductId: $algoliaQueryTrackedProductId
            algoliaFiltersCsv: $algoliaFiltersCsv
            isAlgoliaMiniCartPdpRedirect: $isAlgoliaMiniCartPdpRedirect
            contactFlyoutCsrImage: $contactFlyoutCsrImage
            contactFlyoutCsrName: $contactFlyoutCsrName
            contactFlyoutReview: $contactFlyoutReview
            tableOfContentsHeaderItems: $tableOfContentsHeaderItems
        ) @client
    }
`;

export const setMissingVariantState = gql`
    mutation setMissingVariantState($hasMissingProductVariant: Boolean!) {
        setMissingVariantState(hasMissingProductVariant: $hasMissingProductVariant) @client
    }
`;
