/**
 * Re-validate a previously authenticated session before proceeding to page.
 *
 * @param  {object}   options.app      The application context instance.
 * @param  {object}   options.route    The Vue route instance.
 * @param  {function} options.redirect The redirect helper function.
 */
export default async ({ app, route, redirect }) => {
    // Only validate on client
    if (process.client) {
        const apollo = app.apolloProvider.defaultClient;

        const { getUserAuthState } = await import('@graphql/operations/user');

        const { data } = await apollo.query({
            query: getUserAuthState,
        });

        // Determine if the user is flagged as authenticated in local state but is missing the auth token cookie
        if (data?.user?.isAuthenticated && !app.$apolloHelpers.getToken()) {
            const [
                { setCookie },
                { CPAP_STORAGE_KEY_PREFIX, AUTH_REDIRECT_KEY },
                { redirectWithQueryString },
            ] = await Promise.all([
                import('@utilities/cookies'),
                import('@constants/localStorageKeys'),
                import('@utilities/redirectWithQueryString'),
            ]);

            // Store the attempted route to return the user to after a successful login
            // Cannot use a store due to a bug in Nuxt with middleware redirects
            // See https://github.com/nuxt/nuxt.js/issues/5576
            setCookie(app.$cookies, CPAP_STORAGE_KEY_PREFIX + AUTH_REDIRECT_KEY, route.fullPath);

            redirectWithQueryString(redirect, '/logout', route, app.$cookies);
        }
    }
};
