export const CPAP_STORAGE_KEY_DELIMITER = '.';
export const CPAP_STORAGE_KEY_PREFIX = 'cpap' + CPAP_STORAGE_KEY_DELIMITER;

export const GUEST_ID_STORAGE_KEY = `guest${CPAP_STORAGE_KEY_DELIMITER}id`;
export const SHOPPING_CART_ID_STORAGE_KEY = `cart${CPAP_STORAGE_KEY_DELIMITER}id`;
export const NEWSLETTER_PROMOTION_DIALOG_STORAGE_KEY = 'hasSeenNewsletterPromotionModal';
export const DEFAULT_AUTH_TOKEN_KEY = `auth${CPAP_STORAGE_KEY_DELIMITER}token`;
export const AUTH_REDIRECT_KEY = `auth${CPAP_STORAGE_KEY_DELIMITER}redirect`;
export const REDIRECT_FROM_KEY = `redirect${CPAP_STORAGE_KEY_DELIMITER}from`;
export const PREVIEW_MODE_KEY = `preview_mode_active`;
export const COMPATIBILITY_FILTER_STORAGE_KEY = `compatibilityFilter${CPAP_STORAGE_KEY_DELIMITER}selection${CPAP_STORAGE_KEY_DELIMITER}`;
export const RECENTLY_VIEWED_PRODUCTS = `recentlyViewedProducts`;
export const RECENT_SEARCHES_KEY = 'recentSearches';
export const EXIT_INTENT_KEY = `exitIntent`;
export const USER_REVIEW_VOTES_KEY = `user.review.votes`;

export const ALGOLIA_DEFAULT_USER_TOKEN_COOKIE_KEY = `_ALGOLIA`;
export const ALGOLIA_ANON_RESTORE_USER_TOKEN_COOKIE_KEY = `_ALGOLIA_ANON`;
