<template>
    <NextIcon v-bind:class="$style.icon" />
</template>

<script>
    export default {
        /**
         * Declaratively register required components.
         *
         * @link https://vuejs.org/v2/guide/components.html#Local-Registration
         */
        components: {
            NextIcon: () => import('@fortawesome/fontawesome-pro/svgs/solid/chevron-right.svg'),
        },
    };
</script>

<style module>
    .icon {
        transform: scale(.875);
    }
</style>
