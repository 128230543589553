import Vue from 'vue';

Vue.directive('click-out', {
    bind(element, binding, vnode) {
        element.clickOutEvent = event => {
            // Determine if the click was outside the element and children
            if (!(element === event.target || element.contains(event.target))) {
                // Call method provided by attribute
                vnode.context[binding.expression](event);
            }
        };

        document.body.addEventListener('click', element.clickOutEvent);
    },
    unbind: element => document.body.removeEventListener('click', element.clickOutEvent),
});
