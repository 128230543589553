import { getDialog } from '@src/graphql/operations/dialog';

/**
 * Reusable method to set the dialog state.
 *
 * @param   {Object}   variables  The mutation optional variables.
 * @param   {Object}   cache      The Apollo Client cache instance.
 * @param   {Boolean}  isOpen     A flag to denote the dialog open/close state.
 *
 * @return  void
 */
const setDialog = (variables, cache, isOpen = true) => {
    const data = cache.readQuery({
        query: getDialog,
    });

    data.dialog.isOpen = isOpen;
    data.dialog.name = variables.name || '';
    data.dialog.data = {
        data: variables.data || {},
        options: variables.options || {},
        props: variables.props || {},
        emissionHandlers: variables.emissionHandlers || {},
        isPersistent: variables.isPersistent || false,
        virtualPageView: variables.virtualPageView || {},
    };

    cache.writeQuery({
        query: getDialog,
        data,
    });
};

export const mutations = {
    /**
     * Set the dialog data and open the dialog.
     *
     * @param  {Object}  parent         The parent resolver instance.
     * @param  {String}  variables      The state variables.
     * @param  {Object}  context.cache  The Apollo Client cache instance.
     */
    openDialog: (parent, variables, { cache }) => {
        setDialog(variables, cache);
    },

    /**
     * Clear the dialog data and close the dialog.
     *
     * @param  {Object}  parent         The parent resolver instance.
     * @param  {String}  variables      The state variables.
     * @param  {Object}  context.cache  The Apollo Client cache instance.
     */
    closeDialog: (parent, variables, { cache }) => {
        setDialog({}, cache, false);
    },

    /**
     * Replace the currently active dialog with another dialog.
     *
     * @param  {Object}  parent         The parent resolver instance.
     * @param  {String}  variables      The state variables.
     * @param  {Object}  context.cache  The Apollo Client cache instance.
     */
    replaceDialog: (parent, variables, { cache }) => {
        setDialog({}, cache, false);

        // Open in the next event cycle
        setTimeout(() => setDialog(variables, cache));
    },
};

export const initialCacheState = {
    dialog: {
        isOpen: false,
        name: '',
        // This breaks about every strict typing rule in the book but you cannot set custom
        // scalars in ApolloClient yet, so this is a hack that will allow the field to start
        // as an empty collection and become the required JSON dataset on mutation resolution.
        // See
        //  - https://github.com/apollographql/apollo-client/issues/585
        //  - https://github.com/apollographql/apollo-feature-requests/issues/2
        //  - https://github.com/apollographql/apollo-link-state/issues/205
        data: [],
        __typename: 'Dialog',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
/////////////////////
////////////////////////////
/////////////////////////
///////////////////////
/////////
//////
//

////////////////////////////////////////////////////

//////////////////////////
/////////////////
//////////////
/////////////////
///////////////
//////////////////////////
/////////////////////////
//

////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////
/////////////////////////////////////////////////
//
//////////
