import { gql } from 'graphql-tag';

export const getSplitTests = gql`
    query getSplitTests {
        splitTests @client {
            label
            experimentId
            variationId
        }
    }
`;

export const addSplitTest = gql`
    mutation addSplitTest($label: String!, $experimentId: String!, $variationId: Number!) {
        addSplitTest(label: $label, experimentId: $experimentId, variationId: $variationId) @client
    }
`;
