import { logResolvedRoute } from '@graphql/operations/routing';

/**
 * Increment the route resolution count on each router view change.
 *
 * @param  {object} options.store The Vuex store instance.
 */
export default ({ app, route }) => {
    // Only log the resolved route on the client. The initial route resolution from
    // the first SSR load will be handled in the layout component's mounted lifecycle.
    if (process.client) {
        // Log the resolved route
        app.apolloProvider.defaultClient.mutate({
            mutation: logResolvedRoute,
            variables: {
                path: route.fullPath,
            },
        });
    }
};
