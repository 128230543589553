import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _888743ac = () => interopDefault(import('../src/pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _3b805a11 = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _6c099be0 = () => interopDefault(import('../src/pages/account/address-book.vue' /* webpackChunkName: "pages/account/address-book" */))
const _180bb0cc = () => interopDefault(import('../src/pages/account/change-password.vue' /* webpackChunkName: "pages/account/change-password" */))
const _bf563c06 = () => interopDefault(import('../src/pages/account/credit-cards.vue' /* webpackChunkName: "pages/account/credit-cards" */))
const _bd6efc4e = () => interopDefault(import('../src/pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _57f6444c = () => interopDefault(import('../src/pages/account/order-history/index.vue' /* webpackChunkName: "pages/account/order-history/index" */))
const _b9cf2ce6 = () => interopDefault(import('../src/pages/account/personal-information.vue' /* webpackChunkName: "pages/account/personal-information" */))
const _00f7411b = () => interopDefault(import('../src/pages/account/prescriptions.vue' /* webpackChunkName: "pages/account/prescriptions" */))
const _f7e3fb82 = () => interopDefault(import('../src/pages/account/product-reviews.vue' /* webpackChunkName: "pages/account/product-reviews" */))
const _6b7204c6 = () => interopDefault(import('../src/pages/account/quick-reorder.vue' /* webpackChunkName: "pages/account/quick-reorder" */))
const _588cfde7 = () => interopDefault(import('../src/pages/account/replacement-parts.vue' /* webpackChunkName: "pages/account/replacement-parts" */))
const _d59b3b0a = () => interopDefault(import('../src/pages/account/subscriptions/index.vue' /* webpackChunkName: "pages/account/subscriptions/index" */))
const _789f3437 = () => interopDefault(import('../src/pages/account/subscriptions/view/_id.vue' /* webpackChunkName: "pages/account/subscriptions/view/_id" */))
const _3ab8da8b = () => interopDefault(import('../src/pages/account/doctor-contact/_id.vue' /* webpackChunkName: "pages/account/doctor-contact/_id" */))
const _53048ec2 = () => interopDefault(import('../src/pages/account/order-history/_id.vue' /* webpackChunkName: "pages/account/order-history/_id" */))
const _6b6827a1 = () => interopDefault(import('../src/pages/account-confirmation.vue' /* webpackChunkName: "pages/account-confirmation" */))
const _a78ff92c = () => interopDefault(import('../src/pages/add-to-cart.vue' /* webpackChunkName: "pages/add-to-cart" */))
const _62be2a84 = () => interopDefault(import('../src/pages/affirm-telesales.vue' /* webpackChunkName: "pages/affirm-telesales" */))
const _ae1b5f90 = () => interopDefault(import('../src/pages/ccpa-notice.vue' /* webpackChunkName: "pages/ccpa-notice" */))
const _4d4c6a92 = () => interopDefault(import('../src/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _0c1eec09 = () => interopDefault(import('../src/pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _52550a27 = () => interopDefault(import('../src/pages/checkout/prescription.vue' /* webpackChunkName: "pages/checkout/prescription" */))
const _3db4798a = () => interopDefault(import('../src/pages/checkout/receipt.vue' /* webpackChunkName: "pages/checkout/receipt" */))
const _8ee1fab0 = () => interopDefault(import('../src/pages/checkout/shopping-cart.vue' /* webpackChunkName: "pages/checkout/shopping-cart" */))
const _24f8635a = () => interopDefault(import('../src/pages/compare-chart.vue' /* webpackChunkName: "pages/compare-chart" */))
const _0a4fc284 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _398fd141 = () => interopDefault(import('../src/pages/cpap-brands.vue' /* webpackChunkName: "pages/cpap-brands" */))
const _993ea1de = () => interopDefault(import('../src/pages/cpap-compare-chart.vue' /* webpackChunkName: "pages/cpap-compare-chart" */))
const _f84c8e1e = () => interopDefault(import('../src/pages/cpap-faq.vue' /* webpackChunkName: "pages/cpap-faq" */))
const _d3bca000 = () => interopDefault(import('../src/pages/cpap-insurance-claim-form/index.vue' /* webpackChunkName: "pages/cpap-insurance-claim-form/index" */))
const _13b1d35e = () => interopDefault(import('../src/pages/cpap-part-finder/index.vue' /* webpackChunkName: "pages/cpap-part-finder/index" */))
const _82506380 = () => interopDefault(import('../src/pages/cpap-resources.vue' /* webpackChunkName: "pages/cpap-resources" */))
const _0e7a510b = () => interopDefault(import('../src/pages/cpap-site-map.vue' /* webpackChunkName: "pages/cpap-site-map" */))
const _9d2f95e2 = () => interopDefault(import('../src/pages/cpap-sleep-apnea-test.vue' /* webpackChunkName: "pages/cpap-sleep-apnea-test" */))
const _bf3ef93e = () => interopDefault(import('../src/pages/cpap-talk.vue' /* webpackChunkName: "pages/cpap-talk" */))
const _30c1ab6a = () => interopDefault(import('../src/pages/customize/index.vue' /* webpackChunkName: "pages/customize/index" */))
const _42a8031c = () => interopDefault(import('../src/pages/editorial-ethics.vue' /* webpackChunkName: "pages/editorial-ethics" */))
const _6966c8f6 = () => interopDefault(import('../src/pages/find.vue' /* webpackChunkName: "pages/find" */))
const _06275d28 = () => interopDefault(import('../src/pages/home-sleep-apnea-test.vue' /* webpackChunkName: "pages/home-sleep-apnea-test" */))
const _20ca444d = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _458f4dd6 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _79e8b326 = () => interopDefault(import('../src/pages/mobile-terms-conditions.vue' /* webpackChunkName: "pages/mobile-terms-conditions" */))
const _2253ab7c = () => interopDefault(import('../src/pages/one-time-login.vue' /* webpackChunkName: "pages/one-time-login" */))
const _797f3bf5 = () => interopDefault(import('../src/pages/paypal-success.vue' /* webpackChunkName: "pages/paypal-success" */))
const _2613c682 = () => interopDefault(import('../src/pages/phi-privacy-policy.vue' /* webpackChunkName: "pages/phi-privacy-policy" */))
const _617bcdec = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _2183e718 = () => interopDefault(import('../src/pages/replace.vue' /* webpackChunkName: "pages/replace" */))
const _43d46e25 = () => interopDefault(import('../src/pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _4c342998 = () => interopDefault(import('../src/pages/reset-password-confirm.vue' /* webpackChunkName: "pages/reset-password-confirm" */))
const _9f5683b2 = () => interopDefault(import('../src/pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _44d83264 = () => interopDefault(import('../src/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _2ac7cd8e = () => interopDefault(import('../src/pages/submit-cpap-question.vue' /* webpackChunkName: "pages/submit-cpap-question" */))
const _2f257a96 = () => interopDefault(import('../src/pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _038bb3d4 = () => interopDefault(import('../src/pages/user-agreement.vue' /* webpackChunkName: "pages/user-agreement" */))
const _6537dc8d = () => interopDefault(import('../src/pages/why-shop-with-cpap-dot-com.vue' /* webpackChunkName: "pages/why-shop-with-cpap-dot-com" */))
const _78096121 = () => interopDefault(import('../src/pages/return/label.vue' /* webpackChunkName: "pages/return/label" */))
const _6385cf22 = () => interopDefault(import('../src/pages/return/service.vue' /* webpackChunkName: "pages/return/service" */))
const _6fd3d2a9 = () => interopDefault(import('../src/pages/cpap-insurance-claim-form/_providerName.vue' /* webpackChunkName: "pages/cpap-insurance-claim-form/_providerName" */))
const _2df7552c = () => interopDefault(import('../src/pages/cpap-part-finder/_category/index.vue' /* webpackChunkName: "pages/cpap-part-finder/_category/index" */))
const _730ebe66 = () => interopDefault(import('../src/pages/listing/_slug/index.vue' /* webpackChunkName: "pages/listing/_slug/index" */))
const _65cf6ccf = () => interopDefault(import('../src/pages/productpage-replaceparts/_endpoint/index.vue' /* webpackChunkName: "pages/productpage-replaceparts/_endpoint/index" */))
const _13c7c34a = () => interopDefault(import('../src/pages/productpage/_endpoint/index.vue' /* webpackChunkName: "pages/productpage/_endpoint/index" */))
const _0c4b2a12 = () => interopDefault(import('../src/pages/search/_hash.vue' /* webpackChunkName: "pages/search/_hash" */))
const _b2e7f8d6 = () => interopDefault(import('../src/pages/cpap-part-finder/_category/_subcategory.vue' /* webpackChunkName: "pages/cpap-part-finder/_category/_subcategory" */))
const _9d29ebfe = () => interopDefault(import('../src/pages/listing/_slug/_hash.vue' /* webpackChunkName: "pages/listing/_slug/_hash" */))
const _9af7cb30 = () => interopDefault(import('../src/pages/plp/_.vue' /* webpackChunkName: "pages/plp/_" */))
const _437f97da = () => interopDefault(import('../src/pages/category/_.vue' /* webpackChunkName: "pages/category/_" */))
const _5722a136 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _888743ac,
    pathToRegexpOptions: {"strict":true},
    name: "about-us"
  }, {
    path: "/account",
    component: _3b805a11,
    pathToRegexpOptions: {"strict":true},
    name: "account",
    children: [{
      path: "address-book",
      component: _6c099be0,
      pathToRegexpOptions: {"strict":true},
      name: "account-address-book"
    }, {
      path: "change-password",
      component: _180bb0cc,
      pathToRegexpOptions: {"strict":true},
      name: "account-change-password"
    }, {
      path: "credit-cards",
      component: _bf563c06,
      pathToRegexpOptions: {"strict":true},
      name: "account-credit-cards"
    }, {
      path: "favorites",
      component: _bd6efc4e,
      pathToRegexpOptions: {"strict":true},
      name: "account-favorites"
    }, {
      path: "order-history",
      component: _57f6444c,
      pathToRegexpOptions: {"strict":true},
      name: "account-order-history"
    }, {
      path: "personal-information",
      component: _b9cf2ce6,
      pathToRegexpOptions: {"strict":true},
      name: "account-personal-information"
    }, {
      path: "prescriptions",
      component: _00f7411b,
      pathToRegexpOptions: {"strict":true},
      name: "account-prescriptions"
    }, {
      path: "product-reviews",
      component: _f7e3fb82,
      pathToRegexpOptions: {"strict":true},
      name: "account-product-reviews"
    }, {
      path: "quick-reorder",
      component: _6b7204c6,
      pathToRegexpOptions: {"strict":true},
      name: "account-quick-reorder"
    }, {
      path: "replacement-parts",
      component: _588cfde7,
      pathToRegexpOptions: {"strict":true},
      name: "account-replacement-parts"
    }, {
      path: "subscriptions",
      component: _d59b3b0a,
      pathToRegexpOptions: {"strict":true},
      name: "account-subscriptions"
    }, {
      path: "subscriptions/view/:id?",
      component: _789f3437,
      pathToRegexpOptions: {"strict":true},
      name: "account-subscriptions-view-id"
    }, {
      path: "doctor-contact/:id?",
      component: _3ab8da8b,
      pathToRegexpOptions: {"strict":true},
      name: "account-doctor-contact-id"
    }, {
      path: "order-history/:id?",
      component: _53048ec2,
      pathToRegexpOptions: {"strict":true},
      name: "account-order-history-id"
    }]
  }, {
    path: "/account-confirmation",
    component: _6b6827a1,
    pathToRegexpOptions: {"strict":true},
    name: "account-confirmation"
  }, {
    path: "/add-to-cart",
    component: _a78ff92c,
    pathToRegexpOptions: {"strict":true},
    name: "add-to-cart"
  }, {
    path: "/affirm-telesales",
    component: _62be2a84,
    pathToRegexpOptions: {"strict":true},
    name: "affirm-telesales"
  }, {
    path: "/ccpa-notice",
    component: _ae1b5f90,
    pathToRegexpOptions: {"strict":true},
    name: "ccpa-notice"
  }, {
    path: "/checkout",
    component: _4d4c6a92,
    pathToRegexpOptions: {"strict":true},
    name: "checkout",
    children: [{
      path: "payment",
      component: _0c1eec09,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-payment"
    }, {
      path: "prescription",
      component: _52550a27,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-prescription"
    }, {
      path: "receipt",
      component: _3db4798a,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-receipt"
    }, {
      path: "shopping-cart",
      component: _8ee1fab0,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-shopping-cart"
    }]
  }, {
    path: "/compare-chart",
    component: _24f8635a,
    pathToRegexpOptions: {"strict":true},
    name: "compare-chart"
  }, {
    path: "/contact",
    component: _0a4fc284,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/cpap-brands",
    component: _398fd141,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-brands"
  }, {
    path: "/cpap-compare-chart",
    component: _993ea1de,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-compare-chart"
  }, {
    path: "/cpap-faq",
    component: _f84c8e1e,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-faq"
  }, {
    path: "/cpap-insurance-claim-form",
    component: _d3bca000,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-insurance-claim-form"
  }, {
    path: "/cpap-part-finder",
    component: _13b1d35e,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-part-finder"
  }, {
    path: "/cpap-resources",
    component: _82506380,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-resources"
  }, {
    path: "/cpap-site-map",
    component: _0e7a510b,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-site-map"
  }, {
    path: "/cpap-sleep-apnea-test",
    component: _9d2f95e2,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-sleep-apnea-test"
  }, {
    path: "/cpap-talk",
    component: _bf3ef93e,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-talk"
  }, {
    path: "/customize",
    component: _30c1ab6a,
    pathToRegexpOptions: {"strict":true},
    name: "customize"
  }, {
    path: "/editorial-ethics",
    component: _42a8031c,
    pathToRegexpOptions: {"strict":true},
    name: "editorial-ethics"
  }, {
    path: "/find",
    component: _6966c8f6,
    pathToRegexpOptions: {"strict":true},
    name: "find"
  }, {
    path: "/home-sleep-apnea-test",
    component: _06275d28,
    pathToRegexpOptions: {"strict":true},
    name: "home-sleep-apnea-test"
  }, {
    path: "/login",
    component: _20ca444d,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/logout",
    component: _458f4dd6,
    pathToRegexpOptions: {"strict":true},
    name: "logout"
  }, {
    path: "/mobile-terms-conditions",
    component: _79e8b326,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-terms-conditions"
  }, {
    path: "/one-time-login",
    component: _2253ab7c,
    pathToRegexpOptions: {"strict":true},
    name: "one-time-login"
  }, {
    path: "/paypal-success",
    component: _797f3bf5,
    pathToRegexpOptions: {"strict":true},
    name: "paypal-success"
  }, {
    path: "/phi-privacy-policy",
    component: _2613c682,
    pathToRegexpOptions: {"strict":true},
    name: "phi-privacy-policy"
  }, {
    path: "/privacy",
    component: _617bcdec,
    pathToRegexpOptions: {"strict":true},
    name: "privacy"
  }, {
    path: "/replace",
    component: _2183e718,
    pathToRegexpOptions: {"strict":true},
    name: "replace"
  }, {
    path: "/reset-password",
    component: _43d46e25,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password"
  }, {
    path: "/reset-password-confirm",
    component: _4c342998,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-confirm"
  }, {
    path: "/returns",
    component: _9f5683b2,
    pathToRegexpOptions: {"strict":true},
    name: "returns"
  }, {
    path: "/signup",
    component: _44d83264,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/submit-cpap-question",
    component: _2ac7cd8e,
    pathToRegexpOptions: {"strict":true},
    name: "submit-cpap-question"
  }, {
    path: "/unsubscribe",
    component: _2f257a96,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe"
  }, {
    path: "/user-agreement",
    component: _038bb3d4,
    pathToRegexpOptions: {"strict":true},
    name: "user-agreement"
  }, {
    path: "/why-shop-with-cpap-dot-com",
    component: _6537dc8d,
    pathToRegexpOptions: {"strict":true},
    name: "why-shop-with-cpap-dot-com"
  }, {
    path: "/return/label",
    component: _78096121,
    pathToRegexpOptions: {"strict":true},
    name: "return-label"
  }, {
    path: "/return/service",
    component: _6385cf22,
    pathToRegexpOptions: {"strict":true},
    name: "return-service"
  }, {
    path: "/cpap-insurance-claim-form/:providerName?",
    component: _6fd3d2a9,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-insurance-claim-form-providerName"
  }, {
    path: "/cpap-part-finder/:category",
    component: _2df7552c,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-part-finder-category"
  }, {
    path: "/listing/:slug",
    component: _730ebe66,
    pathToRegexpOptions: {"strict":true},
    name: "listing-slug"
  }, {
    path: "/productpage-replaceparts/:endpoint",
    component: _65cf6ccf,
    pathToRegexpOptions: {"strict":true},
    name: "productpage-replaceparts-endpoint"
  }, {
    path: "/productpage/:endpoint",
    component: _13c7c34a,
    pathToRegexpOptions: {"strict":true},
    name: "productpage-endpoint"
  }, {
    path: "/search/:hash?",
    component: _0c4b2a12,
    pathToRegexpOptions: {"strict":true},
    name: "search-hash"
  }, {
    path: "/cpap-part-finder/:category?/:subcategory?",
    component: _b2e7f8d6,
    pathToRegexpOptions: {"strict":true},
    name: "cpap-part-finder-category-subcategory"
  }, {
    path: "/listing/:slug?/:hash",
    component: _9d29ebfe,
    pathToRegexpOptions: {"strict":true},
    name: "listing-slug-hash"
  }, {
    path: "/plp/*",
    component: _9af7cb30,
    pathToRegexpOptions: {"strict":true},
    name: "plp-all"
  }, {
    path: "/category/*",
    component: _437f97da,
    pathToRegexpOptions: {"strict":true},
    name: "category-all"
  }, {
    path: "/",
    component: _5722a136,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
