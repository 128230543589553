import { getCartState } from '@graphql/operations/cart';

export const mutations = {
    /**
     * Set the cached client cart ID.
     *
     * @param  {Object}  response       The mutation response (not applicable).
     * @param  {Boolean} options.id     The cart ID mutation value.
     * @param  {Object}  options.cache  The Apollo Client cache instance.
     */
    setCachedCartId: (parent, { id }, { cache }) => {
        // Read the cached query
        const data = cache.readQuery({
            query: getCartState,
        });

        // Update client-only cached cart ID
        data.clientCart.cachedId = id;

        // Write the update back to the Apollo cache
        cache.writeData({
            data,
        });
    },

    /**
     * Set the cart transition states.
     *
     * @param  {object}  response            The mutation response (not applicable).
     * @param  {boolean} options.isMutating  The cart mutation state.
     * @param  {boolean} options.isOrdering  The cart ordering state.
     * @param  {object}  options.cache       The Apollo Client cache instance.
     */
    setCartState: (parent, { isMutating, isOrdering }, { cache }) => {
        // Read the cached query
        const data = cache.readQuery({
            query: getCartState,
        });

        // Update client-only mutation state
        if (typeof isMutating !== 'undefined') {
            data.clientCart.isMutating = isMutating;
        }

        // Update client-only ordering state
        if (typeof isOrdering !== 'undefined') {
            data.clientCart.isOrdering = isOrdering;
        }

        // Write the update back to the Apollo cache
        cache.writeData({
            data,
        });
    },

    /**
     * Set the cached client cart ID.
     *
     * @param  {Object}  response       The mutation response (not applicable).
     * @param  {Boolean} options.id     The cart ID mutation value.
     * @param  {Object}  options.cache  The Apollo Client cache instance.
     */
    setPromoCode: (parent, { code }, { cache }) => {
        // Read the cached query
        const data = cache.readQuery({
            query: getCartState,
        });

        // Update client-only cached cart ID
        data.clientCart.promoCode = code;

        // Write the update back to the Apollo cache
        cache.writeData({
            data,
        });
    },

    setShippingAddressState: (parent, { address }, { cache }) => {
        // Read the cached query
        const data = cache.readQuery({
            query: getCartState,
        });

        // Update client-only cached cart shipping address
        data.clientCart.shippingAddress = address;

        // Write the update back to the Apollo cache
        cache.writeData({
            data,
        });
    },
};

export const initialCacheState = {
    clientCart: {
        cachedId: '',
        promoCode: '',
        isMutating: false,
        isOrdering: false,
        shippingAddress: null,
        __typename: 'ClientCart',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
/////////////////////////
/////////////////////////////
//////////////////////////////
////////////////////////////////
////////////////////////////////
/////////////////////////////////////////////////
/////////
//////
//

//////////////////////////////////////////////////////

////////////////////////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//
//////////
