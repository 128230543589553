import { getUserInterfaceState } from '@graphql/operations/userInterface';

export const mutations = {
    /**
     * Set the client-only mobile menu expansion state.
     *
     * @param  {Object}  response            The mutation response (not applicable).
     * @param  {Boolean} options.isExpanded  The menu expansion state value.
     * @param  {Object}  options.cache       The Apollo Client cache instance.
     */
    setUserInterfaceState: (
        response,
        {
            isHeaderFixedToTop,
            isMobileMenuExpanded,
            isMobileAccountMenuExpanded,
            isMobileAccountHeaderMenuExpanded,
            isSearchBarExpanded,
            isContactFlyoutOpen,
            isProductAddToCartStickyActive,
            isCompatibilityFilterModalOpen,
            isMinimalCheckoutActive,
            homepageBannerMobileWidth,
            homepageBannerMobileHeight,
            homepageBannerTabletWidth,
            homepageBannerTabletHeight,
            homepageBannerDesktopWidth,
            homepageBannerDesktopHeight,
            hasInteracted,
            searchQuery,
            algoliaQueryId,
            algoliaQueryTrackedProductId,
            algoliaFiltersCsv,
            isAlgoliaMiniCartPdpRedirect,
            contactFlyoutCsrImage,
            contactFlyoutCsrName,
            contactFlyoutReview,
            tableOfContentsHeaderItems,
        },
        { cache },
    ) => {
        const data = cache.readQuery({
            query: getUserInterfaceState,
        });

        if (typeof isHeaderFixedToTop === 'boolean') {
            data.userInterface.isHeaderFixedToTop = isHeaderFixedToTop;
        }

        if (typeof isMobileMenuExpanded === 'boolean') {
            data.userInterface.isMobileMenuExpanded = isMobileMenuExpanded;
        }

        if (typeof isMobileAccountMenuExpanded === 'boolean') {
            data.userInterface.isMobileAccountMenuExpanded = isMobileAccountMenuExpanded;
        }

        if (typeof isMobileAccountHeaderMenuExpanded === 'boolean') {
            data.userInterface.isMobileAccountHeaderMenuExpanded = isMobileAccountHeaderMenuExpanded;
        }

        if (typeof isSearchBarExpanded === 'boolean') {
            data.userInterface.isSearchBarExpanded = isSearchBarExpanded;
        }

        if (typeof isContactFlyoutOpen === 'boolean') {
            data.userInterface.isContactFlyoutOpen = isContactFlyoutOpen;
        }

        if (typeof isProductAddToCartStickyActive === 'boolean') {
            data.userInterface.isProductAddToCartStickyActive = isProductAddToCartStickyActive;
        }

        if (typeof isCompatibilityFilterModalOpen === 'boolean') {
            data.userInterface.isCompatibilityFilterModalOpen = isCompatibilityFilterModalOpen;
        }

        if (typeof isMinimalCheckoutActive === 'boolean') {
            data.userInterface.isMinimalCheckoutActive = isMinimalCheckoutActive;
        }

        if (typeof hasInteracted === 'boolean') {
            data.userInterface.hasInteracted = hasInteracted;
        }

        if (typeof homepageBannerMobileWidth !== 'undefined') {
            data.userInterface.homepageBannerMobileWidth = homepageBannerMobileWidth || 0;
        }

        if (typeof homepageBannerMobileHeight !== 'undefined') {
            data.userInterface.homepageBannerMobileHeight = homepageBannerMobileHeight || 0;
        }

        if (typeof homepageBannerTabletWidth !== 'undefined') {
            data.userInterface.homepageBannerTabletWidth = homepageBannerTabletWidth || 0;
        }

        if (typeof homepageBannerTabletHeight !== 'undefined') {
            data.userInterface.homepageBannerTabletHeight = homepageBannerTabletHeight || 0;
        }

        if (typeof homepageBannerDesktopWidth !== 'undefined') {
            data.userInterface.homepageBannerDesktopWidth = homepageBannerDesktopWidth || 0;
        }

        if (typeof homepageBannerDesktopHeight !== 'undefined') {
            data.userInterface.homepageBannerDesktopHeight = homepageBannerDesktopHeight || 0;
        }

        if (typeof searchQuery === 'string') {
            data.userInterface.searchQuery = searchQuery || '';
        }

        if (typeof algoliaQueryId === 'string') {
            data.userInterface.algoliaQueryId = algoliaQueryId || '';
        }

        if (typeof algoliaQueryTrackedProductId === 'string') {
            data.userInterface.algoliaQueryTrackedProductId = algoliaQueryTrackedProductId || '';
        }

        if (typeof isAlgoliaMiniCartPdpRedirect === 'boolean') {
            data.userInterface.isAlgoliaMiniCartPdpRedirect = isAlgoliaMiniCartPdpRedirect || false;
        }

        if (typeof algoliaFiltersCsv === 'string') {
            data.userInterface.algoliaFiltersCsv = algoliaFiltersCsv || '';
        }

        if (typeof contactFlyoutCsrImage === 'string') {
            data.userInterface.contactFlyoutCsrImage = contactFlyoutCsrImage || '';
        }

        if (typeof contactFlyoutCsrName === 'string') {
            data.userInterface.contactFlyoutCsrName = contactFlyoutCsrName || '';
        }

        if (typeof contactFlyoutReview === 'string') {
            data.userInterface.contactFlyoutReview = contactFlyoutReview || '';
        }

        if (Array.isArray(tableOfContentsHeaderItems)) {
            data.userInterface.tableOfContentsHeaderItems = tableOfContentsHeaderItems || [];
        }

        cache.writeData({ data });
    },

    /**
     * Set the state to determine if a product variant is mising when a user attempts
     * to add a product to their cart.
     *
     * @param  {Object}  response                           The mutation response (not applicable).
     * @param  {Boolean} options.hasMissingProductVariant   The flag to denote if the variant is missing.
     * @param  {Object}  options.cache                      The Apollo Client cache instance.
     */
    setMissingVariantState: (response, { hasMissingProductVariant }, { cache }) => {
        const data = cache.readQuery({
            query: getUserInterfaceState,
        });

        data.userInterface.hasMissingProductVariant = hasMissingProductVariant;

        cache.writeData({ data });
    },
};

export const initialCacheState = {
    userInterface: {
        isHeaderFixedToTop: false,
        isMobileMenuExpanded: false,
        isMobileAccountMenuExpanded: false,
        isMobileAccountHeaderMenuExpanded: false,
        isSearchBarExpanded: false,
        isContactFlyoutOpen: false,
        isProductAddToCartStickyActive: false,
        isCompatibilityFilterModalOpen: false,
        isMinimalCheckoutActive: false,
        hasMissingProductVariant: false,
        hasInteracted: false,
        homepageBannerMobileWidth: 0,
        homepageBannerMobileHeight: 0,
        homepageBannerTabletWidth: 0,
        homepageBannerTabletHeight: 0,
        homepageBannerDesktopWidth: 0,
        homepageBannerDesktopHeight: 0,
        searchQuery: '',
        algoliaQueryId: '',
        algoliaQueryTrackedProductId: '',
        isAlgoliaMiniCartPdpRedirect: false,
        algoliaFiltersCsv: '',
        contactFlyoutCsrImage: '',
        contactFlyoutCsrName: '',
        contactFlyoutReview: '',
        tableOfContentsHeaderItems: [],
        __typename: 'UserInterface',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
////////////////////////////////////////
///////////////////////////
//////////////////////////
/////////

////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////
//////////////////////////////////////////////
////////////////////////////////////
//////////////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////
///////////////////////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////
//////
//

//////////////////////////////////////////////////////////////////

////////////////////////////////////
///////////////////////////
////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
////////////////////////////////////////////
///////////////////////////////
////////////////////////////
///////////////////////////////
/////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////
////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////
////////////////////////////////////////////////////////////////////////////////
//
//////////
