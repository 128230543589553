import Vue from 'vue';

/**
 * Track the page view in Google Analytics.
 *
 * @param  {Object} options.app   The app context object.
 */
export default ({ app }) => {
    // Similar to how the GTM nuxt-module handles auto page tracking
    // with a nextTick, which ensure any async data may be resolved.
    // https://github.com/nuxt-community/gtm-module/blob/master/lib/plugin.js#L68-L76
    // 500ms is default transition time for pages in Nuxt.
    app.router.afterEach(to =>
        Vue.nextTick(() =>
            setTimeout(() => {
                // Do not abstract this to the `gaTracking` utility file
                // to avoid an import into the application entry JS bundle
                app.$gtm.push({
                    event: 'content-view',
                    // Don't send URL fragments to Google Analytics
                    'content-name': to.fullPath.replace(to.hash, ''),
                    'content-view-name': to.name,
                    'content-title': (typeof document !== 'undefined' && document.title) || '',
                });
            }, 750),
        ),
    );
};
