let themeCache;

if (process.server) {
    // Use LRU caching on the server
    const LRU = require('lru-cache');

    themeCache = new LRU({
        max: 10,
        maxAge: 1000 * 60 * 60, // 1 hour
    });
} else if (window.localStorage) {
    // Otherwise use local storage on the client if it is available
    themeCache = {
        get: key => {
            try {
                window.localStorage.getItem(JSON.stringify(key));
            } catch (e) {
                // Client localStorage is likely disabled or full
            }
        },
        set: (key, value) => {
            try {
                window.localStorage.setItem(JSON.stringify(key), value);
            } catch (e) {
                // Client localStorage is likely disabled
            }
        },
    };
}

export default ctx => {
    if (themeCache) {
        ctx.app.vuetify.framework.theme.options.themeCache = themeCache;
    }
};
