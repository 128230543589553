<template>
    <PrevIcon v-bind:class="$style.icon" />
</template>

<script>
    export default {
        /**
         * Declaratively register required components.
         *
         * @link https://vuejs.org/v2/guide/components.html#Local-Registration
         */
        components: {
            PrevIcon: () => import('@fortawesome/fontawesome-pro/svgs/solid/chevron-left.svg'),
        },
    };
</script>

<style module>
    .icon {
        transform: scale(.875) translate(-0.0625em, 0);
    }
</style>
