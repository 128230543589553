import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
    defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from './../../ig.fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

const typePolicies = {
    ProductInterface: {
        keyFields: ['uid'],
    },
    CustomAttributeMetadata: {
        // Until we move to Vue 3 with the newer Vue Apollo version,
        // nested key fields can only be one-level deep
        keyFields: ['items', ['attribute_code']],
    },
    ConfigurableAttributeOption: {
        keyFields: ['uid'],
    },
    ConfigurableProductOptions: {
        keyFields: ['uid'],
    },
    SelectedConfigurableOption: {
        keyFields: ['configurable_product_option_uid', 'configurable_product_option_value_uid'],
    },
    Attribute: {
        keyFields: ['attribute_code'],
    },
    CustomizableOptionInterface: {
        keyFields: ['uid'],
    },
    CustomizableDropDownValue: {
        keyFields: ['uid'],
    },
    SubscriptionInterval: {
        keyFields: ['uid'],
    },
    Subscription: {
        keyFields: ['entity_id'],
    },
    SubscriptionQuote: {
        keyFields: ['entity_id'],
    },
    SubscriptionQuoteItem: {
        keyFields: ['item_id'],
    },
    SubscriptionQuotePayment: {
        keyFields: ['entity_id'],
    },
    SubscriptionPaymentAccount: {
        keyFields: ['public_hash'],
    },
    SubscriptionLog: {
        keyFields: ['log_id'],
    },
    SubscriptionQuoteBillingAddress: {
        keyFields: ['address_id'],
    },
    SubscriptionQuoteShippingAddress: {
        keyFields: ['address_id'],
    },
    SelectedCustomizableOption: {
        // Until we move to Vue 3 with the newer Vue Apollo version,
        // nested key fields can only be one-level deep
        keyFields: ['customizable_option_uid', ['customizable_option_value_uid']],
    },
    SelectedCustomizableOptionValue: {
        keyFields: ['customizable_option_value_uid'],
    },
    BundleItem: {
        keyFields: ['uid'],
    },
    BundleItemOption: {
        keyFields: ['uid'],
    },
    BundleCartItem: {
        keyFields: ['uid'],
    },
    SelectedBundleOptionValue: {
        keyFields: ['uid', 'configurable_options', ['configurable_product_option_value_uid']],
    },
    PrescriptionDetails: {
        keyFields: ['prescription_id'],
    },
    TokenBaseCard: {
        keyFields: ['hash'],
    },
};

const typenameKeyFields = Object.keys(typePolicies).map(typename => ({
    typename,
    keyFields: typePolicies[typename]?.keyFields,
}));

/**
 * Will get the actual cache ID data value from the type key field.
 *
 * @param  {array}  keyFields       The collection of key fields for cache IDs.
 * @param  {object} responseObject  The data object.
 *
 * @return {string}
 */
const getDataIdKeyFromKeyFields = (keyFields, responseObject) => {
    const identifiers = keyFields
        .map(keyField => (typeof keyField === 'string' ? responseObject[keyField] : null))
        .filter(value => typeof value === 'string' || typeof value === 'number');

    return identifiers?.length ? identifiers.join('_') : '';
};

/**
 * Will get the nested cache ID data values from the type key field.
 *
 * @param  {array}  keyFields       The collection of key fields for cache IDs.
 * @param  {object} responseObject  The data object.
 *
 * @return {string}
 */
const getNestedDataIdKeyFromKeyFields = (keyFields, responseObject) => {
    return keyFields
        .map((nestedKeyFields, index) => {
            // Determine if key field is actually an array of keys
            if (nestedKeyFields?.length && Array.isArray(nestedKeyFields)) {
                // Get the base key, which should be the previous key, as a string
                const baseKey = keyFields?.[index - 1];

                // Make sure the base key is as string type so we know which nested collection to use
                if (typeof baseKey === 'string') {
                    const collection = responseObject[baseKey];

                    // Verify that the base collection is an array
                    if (collection?.length && Array.isArray(collection)) {
                        // Map the collection cache keys into a single string
                        return collection
                            .map(entry => getDataIdKeyFromKeyFields(nestedKeyFields, entry))
                            .filter(value => value)
                            .join('_');
                    }
                }
            }

            return null;
        })
        .filter(value => value)
        .join('_');
};

/**
 * Create a cache instance.
 *
 * This must export a function to prevent a singleton
 * instance which will cause SSR server issues.
 * For more information see:
 * https://github.com/nuxt-community/apollo-module/pull/362#issue-504200317
 *
 * @return {InMemoryCache}
 */
export const createCache = () =>
    new InMemoryCache({
        fragmentMatcher,
        // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-globally
        // This is deprecated in the most recent Apollo Client, however
        // this is still required for our current Vue Apollo version
        dataIdFromObject(responseObject) {
            const type = typenameKeyFields.find(
                ({ typename }) => typename === responseObject?.__typename,
            );

            if (!type) {
                return defaultDataIdFromObject(responseObject);
            }

            // Singleton fields will not be cached
            // See https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
            const isSingletonKeyfield = !type.keyFields?.length;

            if (isSingletonKeyfield) {
                return null;
            }

            const identifierKey = getDataIdKeyFromKeyFields(type.keyFields, responseObject);

            if (!identifierKey) {
                return defaultDataIdFromObject(responseObject);
            }

            const baseDataId = `${type.typename}:${identifierKey}`;

            const nestedDataIdKey = getNestedDataIdKeyFromKeyFields(type.keyFields, responseObject);

            return baseDataId + (nestedDataIdKey ? `.${nestedDataIdKey}` : '');
        },
        // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
        // Note: The `keyFields` within the `typePolicies` type data does not currently work
        // with our version of Vue Apollo, use the `dataIdFromObject` function instead.
        typePolicies,
    });
