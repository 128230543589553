/**
 * If a promotion code exists in a URL query string on an
 * internal app NuxtLink, this attempts to apply the coupon.
 *
 * @param  {Object} options.app      The app context object.
 * @param  {Object} options.route    The Vue route instance.
 * @param  {Object} options.$gtm     The Nuxt GTM module instance.
 */
export default async ({ app, route, $gtm }) => {
    const couponCodeInQueryString = route.query?.promo;

    if (couponCodeInQueryString) {
        // This will set the promo code on the @client cart so that
        // server rendered pages will have the query string promo.
        // Client-side applies will occur in the `addPromotionCodeToCart` utility function.
        if (process.server) {
            const apollo = app.apolloProvider.defaultClient;

            const { setPromoCode } = await import('@graphql/operations/cart');

            await apollo.mutate({
                mutation: setPromoCode,
                variables: {
                    code: couponCodeInQueryString,
                },
            });
        }

        // Only process client logic because this is only for NuxtLink routing provided
        // by the CMS that will apply a coupon during an internal application route change.
        // Applying the promo code on the initial app load occurs in the default template. This is
        // because it must wait for the cart to load before apply the coupon on the client.
        if (process.client) {
            // Async non-blocking IIFE to prevent delaying page loads during dynamic import
            (async () => {
                const { addPromotionCodeToCart } = await import(
                    '@utilities/addPromotionCodeToCart'
                );

                addPromotionCodeToCart(
                    app.apolloProvider.defaultClient,
                    couponCodeInQueryString,
                    $gtm,
                );
            })();
        }
    }
};
