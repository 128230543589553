import { gql } from 'graphql-tag';

export const getUserAuthState = gql`
    query getUserAuthState {
        user @client {
            isAuthenticated
        }
    }
`;

export const setUserAuthState = gql`
    mutation setUserAuthState($isAuthenticated: Boolean!) {
        setUserAuthState(isAuthenticated: $isAuthenticated) @client
    }
`;
