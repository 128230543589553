import { gql } from 'graphql-tag';

export const getCustomizationsSkus = gql`
    query getCustomizationsSkus {
        customizations @client {
            skus
        }
    }
`;

export const addCustomizationProduct = gql`
    mutation addCustomizationProduct($sku: String!) {
        addCustomizationProduct(sku: $sku) @client
    }
`;

export const removeCustomizationProduct = gql`
    mutation removeCustomizationProduct($sku: String!) {
        removeCustomizationProduct(sku: $sku) @client
    }
`;

export const resetCustomizationProductSkus = gql`
    mutation resetCustomizationProductSkus {
        resetCustomizationProductSkus @client
    }
`;
