import { gql } from 'graphql-tag';

export const getSmsSignupState = gql`
    query getSmsSignupState {
        smsSignup @client {
            isOptingInForSms
            email
        }
    }
`;

export const setSmsSignupState = gql`
    mutation setSmsSignupState($isOptingInForSms: Boolean, $email: String) {
        setSmsSignupState(isOptingInForSms: $isOptingInForSms, email: $email) @client
    }
`;

export const resetSmsSignupState = gql`
    mutation resetSmsSignupState {
        resetSmsSignupState @client
    }
`;
