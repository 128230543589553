import { getCustomizationsSkus } from '@src/graphql/operations/customizations';

export const mutations = {
    /**
     * Mutate the `customizations` state by adding product sku.
     *
     * @param  {Object}  parent          The parent resolver instance.
     * @param  {String}  options.sku     The product sku mutation value.
     * @param  {Object}  options.cache   The Apollo Client cache instance.
     */
    addCustomizationProduct: (parent, { sku }, { cache }) => {
        const data = cache.readQuery({
            query: getCustomizationsSkus,
        });

        data.customizations.skus = [sku, ...data.customizations.skus];

        cache.writeData({
            data,
        });
    },

    /**
     * Mutate the `customizations` state by removing product sku.
     *
     * @param  {Object}  parent             The parent resolver instance.
     * @param  {String}  options.sku        The product sku mutation value.
     * @param  {Object}  options.cache      The Apollo Client cache instance.
     */
    removeCustomizationProduct: (parent, { sku }, { cache }) => {
        const data = cache.readQuery({
            query: getCustomizationsSkus,
        });

        data.customizations.skus = data.customizations.skus.filter(
            productSku => productSku !== sku,
        );

        cache.writeData({
            data,
        });
    },

    /**
     * Resets the customization product skus to empty array.
     *
     * @param  {Object}  response       The mutation response (not applicable).
     * @param  {Boolean} variables      The mutation variables (not applicable).
     * @param  {Object}  options.cache  The Apollo Client cache instance.
     */
    resetCustomizationProductSkus: (response, variables, { cache }) => {
        const data = cache.readQuery({
            query: getCustomizationsSkus,
        });

        // Remove all skus from array.
        data.customizations.skus = [];

        // Write the updated data to Apollo cache
        cache.writeData({ data });
    },
};

export const initialCacheState = {
    customizations: {
        skus: [],
        __typename: 'Customizations',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
/////////////////////////////
///////////////////////////
/////////
//////
//

////////////////////////////////////////////////////////////////////

////////////////////////////////////
////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////
//
//////////
