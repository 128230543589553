/**
 * Log an Error instance asynchronously.
 *
 * @param  {Error}   error                   The error instance.
 * @param  {object}  additionalDetails       Additional data to be logged.
 * @param  {boolean} isReloggingGraphqlError Allow for relogging GraphQL errors after
 *                                           the Apollo link error handler.
 */
export async function logError (error, additionalDetails = {}, isReloggingGraphqlError = false) {
    // Log the error
    const { default: ErrorHandler } = await import('@classes/Error/Handler');

    ErrorHandler.log(error, additionalDetails, isReloggingGraphqlError);
}
