import { gql } from 'graphql-tag';

export const getCheckoutState = gql`
    query getCheckoutState {
        checkout @client {
            isBillingSameAsShipping
            isUpdatingShippingAddress
            isValidatingShippingAddress
            hasValidShippingAddress
            isProcessingPrescriptionUpload
            isUpdatingCartVariantSelection
            isRefetchingShippingMethods
            isProcessingShippingMethod
            hasPresetCartSessionShippingMethod
            isCartSessionShippingMethodOutdated
            isUpdatingPromoCode
            isRemovingPromoCode
            isPrescriptionOptionComplete
            selectedPrescriptionOptionLabel
            updatedAddressId
            prescriptions {
                id
                filename
            }
            pendingPrescriptions {
                filename
                status
            }
            isUploadingPrescriptions
            isPrescriptionUploadComplete
            doctorContactDetails {
                doctorid
                birthday
                agree
                doctorStatusId
                name
                phone
            }
            couponCode
            previewShippingPrice
        }
    }
`;

export const setCheckoutState = gql`
    mutation setCheckoutState(
        $isBillingSameAsShipping: Boolean
        $isUpdatingShippingAddress: Boolean
        $isValidatingShippingAddress: Boolean
        $hasValidShippingAddress: Boolean
        $isProcessingPrescriptionUpload: Boolean
        $isUpdatingCartVariantSelection: Boolean
        $isRefetchingShippingMethods: Boolean
        $isProcessingShippingMethod: Boolean
        $hasPresetCartSessionShippingMethod: Boolean
        $isCartSessionShippingMethodOutdated: Boolean
        $isUpdatingPromoCode: Boolen
        $isRemovingPromoCode: Boolean
        $isPrescriptionOptionComplete: Boolean
        $selectedPrescriptionOptionLabel: String
        $updatedAddressId: Number
        $prescriptions: [Prescription]
        $pendingPrescriptions: [PendingPrescription]
        $isUploadingPrescriptions: Boolean
        $isPrescriptionUploadComplete: Boolean
        $doctorContactDetails: DoctorContactDetails
        $couponCode: String
        $previewShippingPrice: Float
    ) {
        setCheckoutState(
            isBillingSameAsShipping: $isBillingSameAsShipping
            isUpdatingShippingAddress: $isUpdatingShippingAddress
            isValidatingShippingAddress: $isValidatingShippingAddress
            hasValidShippingAddress: $hasValidShippingAddress
            isProcessingPrescriptionUpload: $isProcessingPrescriptionUpload
            isUpdatingCartVariantSelection: $isUpdatingCartVariantSelection
            isRefetchingShippingMethods: $isRefetchingShippingMethods
            isProcessingShippingMethod: $isProcessingShippingMethod
            hasPresetCartSessionShippingMethod: $hasPresetCartSessionShippingMethod
            isCartSessionShippingMethodOutdated: $isCartSessionShippingMethodOutdated
            isUpdatingPromoCode: $isUpdatingPromoCode
            isRemovingPromoCode: $isRemovingPromoCode
            isPrescriptionOptionComplete: $isPrescriptionOptionComplete
            selectedPrescriptionOptionLabel: $selectedPrescriptionOptionLabel
            updatedAddressId: $updatedAddressId
            prescriptions: $prescriptions
            pendingPrescriptions: $pendingPrescriptions
            isUploadingPrescriptions: $isUploadingPrescriptions
            isPrescriptionUploadComplete: $isPrescriptionUploadComplete
            doctorContactDetails: $doctorContactDetails
            couponCode: $couponCode
            previewShippingPrice: $previewShippingPrice
        ) @client
    }
`;

export const removeDoctorContactDetails = gql`
    mutation removeDoctorContactDetails {
        removeDoctorContactDetails @client
    }
`;

export const removeDeferredCouponCodeFromCheckoutState = gql`
    mutation removeDeferredCouponCodeFromCheckoutState {
        removeDeferredCouponCodeFromCheckoutState @client
    }
`;
