/**
 * Authenticate the user before proceeding to page.
 *
 * @param  {Object}   options.app      The application context instance.
 * @param  {Object}   options.route    The Vue route instance.
 * @param  {Function} options.redirect The redirect helper function.
 */
export default async ({ app, route, redirect }) => {
    // Determine if the user has an auth token
    if (!app.$apolloHelpers.getToken()) {
        const [
            { setCookie },
            { CPAP_STORAGE_KEY_PREFIX, AUTH_REDIRECT_KEY },
            { redirectWithQueryString },
        ] = await Promise.all([
            import('@utilities/cookies'),
            import('@constants/localStorageKeys'),
            import('@utilities/redirectWithQueryString'),
        ]);

        // Store the attempted route to return the user to after a successful login
        // Cannot use a store due to a bug in Nuxt with middleware redirects
        // See https://github.com/nuxt/nuxt.js/issues/5576
        setCookie(app.$cookies, CPAP_STORAGE_KEY_PREFIX + AUTH_REDIRECT_KEY, route.fullPath);

        redirectWithQueryString(redirect, '/logout', route, app.$cookies);
    }
};
