import { gql } from 'graphql-tag';
import { ProductCategoriesFragment } from './magento/usx/fragments/product';
import { ConfigurableProductOptionsFragment } from './magento/usx/fragments/ConfigurableProductOptionsFragment';
import { ConfigurableAttributeOptionFragment } from './magento/usx/fragments/ConfigurableAttributeOptionFragment';
import { SelectedConfigurableOptionFragment } from './magento/usx/fragments/SelectedConfigurableOptionFragment';
import { SubscriptionIntervalFragment } from './magento/usx/fragments/SubscriptionIntervalFragment';
import {
    DiscountSummaryFragment,
    GrandTotalPriceFragment,
    SubTotalPriceFragment,
    TaxSummaryFragment,
} from './magento/usx/fragments/priceSummary';

export const getOrderConfirmationDetails = gql`
    query getOrderConfirmationDetails {
        orderConfirmation @client {
            email
            address {
                firstname
                lastname
                city
                company
                country {
                    code
                    label
                }
                postcode
                region {
                    label
                    region_id
                }
                street
                telephone
            }
            orderDate
            orderNumber
            items {
                ... on ConfigurableCartItem {
                    configurable_options {
                        ...SelectedConfigurableOptionFragment
                    }
                }
                prices {
                    price {
                        currency
                        value
                    }
                    total_item_discount {
                        value
                    }
                    discounts {
                        label
                    }
                }
                product {
                    uid # Required for the Apollo cache unique fragment identifier
                    id # Required for the Apollo cache unique fragment identifier
                    sku
                    main_image
                    name
                    selectedVariantLabel
                    url_key
                    displayable_in_stock
                    displayable_allow_backorders
                    # The 'manufacturer' key must always be included for 'brand' to return proper data
                    manufacturer
                    brand {
                        id
                        label
                    }
                    price_tiers {
                        quantity
                    }
                    advanced_pricing {
                        msrp_display_actual_price_type
                    }
                    categories {
                        ...ProductCategoriesFragment
                    }
                    ... on ConfigurableProduct {
                        configurable_options {
                            ...ConfigurableProductOptionsFragment
                        }
                        variants {
                            attributes {
                                ...ConfigurableAttributeOptionFragment
                            }
                            product {
                                uid # Required for the Apollo cache unique fragment identifier
                                id # Required for the Apollo cache unique fragment identifier
                                sku
                                name
                                main_image
                                displayable_in_stock
                                displayable_allow_backorders
                                # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                                product_availability
                                product_availability_label
                                price_range {
                                    minimum_price {
                                        final_price {
                                            value
                                        }
                                        regular_price {
                                            value
                                        }
                                        discount {
                                            amount_off
                                            percent_off
                                        }
                                    }
                                }
                            }
                        }
                    }
                    price_range {
                        minimum_price {
                            final_price {
                                value
                            }
                            regular_price {
                                value
                            }
                            discount {
                                amount_off
                                percent_off
                            }
                        }
                    }
                }
                quantity
                subscription {
                    ...SubscriptionIntervalFragment
                }
                ... on BundleCartItem {
                    bundle_options {
                        uid # Required for the Apollo cache unique fragment identifier
                        values {
                            uid # Required for the Apollo cache unique fragment identifier
                            label
                            price
                            quantity
                            product {
                                uid # Required for the Apollo cache unique fragment identifier
                                id # Required for the Apollo cache unique fragment identifier
                                rx_required
                            }
                            configurable_options {
                                ...SelectedConfigurableOptionFragment
                            }
                        }
                    }
                }
            }
            priceSummary {
                ...TaxSummaryFragment
                ...DiscountSummaryFragment
                ...SubTotalPriceFragment
                ...GrandTotalPriceFragment
            }
            shippingMethod {
                amount {
                    value
                }
                method_title
            }
            subscribed
            defaultAddressParameters
            paymentMethod {
                code
                title
                type
                expressMethod
            }
        }
    }
    ${TaxSummaryFragment}
    ${SubTotalPriceFragment}
    ${DiscountSummaryFragment}
    ${GrandTotalPriceFragment}
    ${ProductCategoriesFragment}
    ${ConfigurableProductOptionsFragment}
    ${ConfigurableAttributeOptionFragment}
    ${SelectedConfigurableOptionFragment}
    ${SubscriptionIntervalFragment}
`;

export const getAccountCreationStatus = gql`
    query getAccountCreationStatus {
        wasAccountCreated @client {
            status
        }
    }
`;

export const setOrderConfirmationState = gql`
    mutation setOrderConfirmationState(
        $email: String!
        $address: CartAddressInterface!
        $items: [CartItemInterface]!
        $orderDate: String!
        $orderNumber: String!
        $priceSummary: CartPrices!
        $shippingMethod: SelectedShippingMethod!
        $subscribed: Boolean!
        $defaultAddressParameters: ConfirmationAddress!
        $paymentMethod: ConfirmationPaymentMethod!
    ) {
        setOrderConfirmationState(
            email: $email
            address: $address
            items: $items
            orderDate: $orderDate
            orderNumber: $orderNumber
            priceSummary: $priceSummary
            shippingMethod: $shippingMethod
            subscribed: $subscribed
            defaultAddressParameters: $defaultAddressParameters
            paymentMethod: $paymentMethod
        ) @client
    }
`;

export const setAccountCreationStatus = gql`
    mutation setAccountCreationStatus($wasAccountCreated: Boolean!) {
        setAccountCreationStatus(wasAccountCreated: $wasAccountCreated) @client
    }
`;
