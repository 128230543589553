// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n._24Pkr {\n\n    padding-top: 2rem !important;\n\n    padding-bottom: 2rem !important\n}\n.P-VxQ {\n\n    padding-top: 0px !important;\n\n    padding-bottom: 0px !important\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_24Pkr",
	"container404": "P-VxQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
