import { getMobileMenuFooterFixed } from '@src/graphql/operations/mobileMenuFooterFixed';

/**
 * Reusable method to set the sticky footer menu state.
 *
 * @param   {Object}   variables  The mutation optional variables.
 * @param   {Object}   cache      The Apollo Client cache instance.
 *
 * @return  void
 */
const setMobileMenuFooterFixedParameters = (variables, cache) => {
    const data = cache.readQuery({
        query: getMobileMenuFooterFixed,
    });

    data.mobileMenuFooterFixed.data = {
        data: variables.data || {},
        options: variables.options || {},
        props: variables.props || {},
        emissionHandlers: variables.emissionHandlers || {},
    };

    cache.writeQuery({
        query: getMobileMenuFooterFixed,
        data,
    });
};

export const mutations = {
    /**
     * Replace the currently active mobileMenuFooterFixed with another mobileMenuFooterFixed.
     *
     * @param  {Object}  parent         The parent resolver instance.
     * @param  {String}  variables      The state variables.
     * @param  {Object}  context.cache  The Apollo Client cache instance.
     */
    replaceMobileMenuFooterFixedParameters: (parent, variables, { cache }) => {
        setMobileMenuFooterFixedParameters({}, cache);

        // Open in the next event cycle
        setTimeout(() => setMobileMenuFooterFixedParameters(variables, cache));
    },
};

export const initialCacheState = {
    mobileMenuFooterFixed: {
        // This breaks about every strict typing rule in the book but you cannot set custom
        // scalars in ApolloClient yet, so this is a hack that will allow the field to start
        // as an empty collection and become the required JSON dataset on mutation resolution.
        // See
        //  - https://github.com/apollographql/apollo-client/issues/585
        //  - https://github.com/apollographql/apollo-feature-requests/issues/2
        //  - https://github.com/apollographql/apollo-link-state/issues/205
        data: [],
        __typename: 'MobileMenuFooterFixed',
    },
};

// These definitions will NOT provide schema validation.
// They are only used for structure validation and Apollo DevTool introspection.
// See: https://www.apollographql.com/docs/react/local-state/client-side-schema/
///////////////////////////////
////////////////////////////////
/////
////////////////////////////////////
///////////////////////
/////////
//////
//

//////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
//////////////
/////////////////
///////////////
//////////////////////////
//

////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//////////
