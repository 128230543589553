import { CPAP_STORAGE_KEY_PREFIX, REDIRECT_FROM_KEY } from '@constants/localStorageKeys';
import { setCookie } from '@utilities/cookies';

/**
 * Redirect with query string.
 *
 * @param {Object} redirect Vue router redirect function.
 * @param {String} url      URL path.
 * @param {Object} route    Vue router route instance.
 * @param {Object} $cookies The `cookie-universal` instance.
 */
export const redirectWithQueryString = (redirect, url, route, $cookies, excludedQueryStringKeys = []) => {
    const { query, fullPath } = route;
    const queryString = Object.keys(query).length
        ? '?'
        + Object.keys(query)
            .map(key => excludedQueryStringKeys.includes(key) ? null : key + '=' + query[key])
            .filter(keyValue => keyValue)
            .join('&')
        : '';

    // Set the cookie to indicate the route from which this redirect originated.
    // We will later use this to set the `originalLocation` to preserve query parameters.
    setCookie($cookies, CPAP_STORAGE_KEY_PREFIX + REDIRECT_FROM_KEY, fullPath);

    redirect(url + queryString);
};
